import React, {Component} from 'react';
import {CAPABILITIES} from "../utils/dummy";
import CollapseComponent from "./CollapseComponent";
import ArrowRight from "../icons/custom/ArrowRight";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import SvgClose from "../icons/SvgClose";

class Services extends Component {
    state = {
        selectedIndex: 0
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    renderIcon = (capability, i, selected) => {
        switch (capability) {
            case 'Strategy':
                return (
                    <div className={` col-ac ${selected && 'float'}`} onMouseEnter={() => this.selectIndex(i)}>
                        <img src={require('../img/services/graphic3.png')}
                             alt={'services-1'}
                             className={`w-60p transition1`}
                             style={{transform: selected ? 'scale(1.2)' : '', zIndex: -1, opacity: selected ? 1 : .5}}
                        />
                    </div>
                )
            case 'Design':
                return (
                    <div className={`flex-1 col-ac ${selected && 'float'}`} onMouseEnter={() => this.selectIndex(i)}>
                        <img src={require('../img/services/graphic2.png')}
                             alt={'services-2'}
                             className={`w-60p transition1`}
                             style={{transform: selected ? 'scale(1.2)' : '', zIndex: -1, opacity: selected ? 1 : .5}}
                        />
                    </div>
                )
            case 'Implementation':
                return (
                    <div className={` col-ac ${selected && 'float'}`} onMouseEnter={() => this.selectIndex(i)}>
                        <img src={require('../img/services/graphic1.png')}
                             alt={'services-3'}
                             className={`w-60p transition1`}
                             style={{transform: selected ? 'scale(1.2)' : '', zIndex: -1, opacity: selected ? 1 : .5}}
                        />
                    </div>
                )
            default:
                return null
        }
    }

    render() {
        return (
            <section style={{padding: this.props.mobile ? '15vh 0 0 0' : '15vh 0 0 0'}}>
                <div className='page-h-padding mb-32'>
                    <FadeInOnScroll>
                        <h1 className={`heading2 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                            How We Do It
                        </h1>
                    </FadeInOnScroll>
                    <div className={this.props.mobile ? 'row-ac mt-0' : 'row-ac mt-40'}
                         style={{margin: this.props.mobile && '-60px 16px -60px 16px'}}>
                        {CAPABILITIES.map((capability, i) => {
                                if (capability.divider) {
                                    return (
                                        <ArrowRight key={'divider' + i} width={200} height={200}
                                                    fill={this.props.darkBody ? '#ffffff50' : '#00000040'}/>
                                    )
                                } else return (
                                    <FadeInOnScroll delay={i * 100} key={capability.title}>
                                        <div className='row-ac flex-1'>
                                            <div className='flex-1 col-ac-jc'>
                                                {this.renderIcon(capability.title, i, this.state.selectedIndex === i)}
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                )
                            }
                        )}
                    </div>
                </div>
                <div className='services-section col-plain' style={{
                    borderColor: this.props.darkBody && '#ffffff40',
                    minHeight: this.props.tablet ? '55vh' : '55vh'
                }}>
                    <div className='row-plain flex-1'>
                        <ul className={`flex-1 col-plain flex-1 ${!this.props.mobile && 'services-section-left'}`}
                            style={{borderColor: this.props.darkBody && '#ffffff40'}}>
                            {CAPABILITIES.map((capability, i) => {
                                    if (capability.divider) {
                                        return null
                                    } else return (
                                        <li
                                            className={`capability-list-item ${this.props.darkBody && 'h-bg-base-white-8'} ${this.props.mobile ? 'pv-8' : 'pv-20'} col-jc`}
                                            style={{
                                                flex: this.state.selectedIndex === i ? 3 : 1,
                                                borderColor: this.props.darkBody && '#ffffff40',
                                            }}
                                            key={capability.title}
                                            onMouseEnter={() => this.selectIndex(i)}
                                        >
                                            <FadeInOnScroll>
                                                <div className='row-ac-jb mr-28'>
                                                    <p className={`title1 mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                                                        // style={{transform: this.state.selectedIndex === i && 'scale(1.1)'}}
                                                    >
                                                            <span
                                                                className={`${this.props.tablet ? 'base-white-30 mr-8' : 'base-white-30 mr-16'}`}>{capability.number}</span>{capability.title}
                                                    </p>
                                                    <div className='relative'>
                                                        <div style={{
                                                            transform: (this.state.selectedIndex === i) ? 'rotate(45deg)' : 'rotate(0deg)',
                                                            opacity: (this.state.selectedIndex === i) ? 1 : 0,
                                                            transition: '300ms ease-in-out',
                                                            marginTop: 4,
                                                        }}>
                                                            <SvgClose fill={'white'} width={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}
                                                                      height={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FadeInOnScroll>
                                            <CollapseComponent isOpen={this.state.selectedIndex === i}>
                                                <FadeInOnScroll delay={150}>
                                                    <p className={`${this.props.tablet ? 'body2' : 'body1'} ${this.props.darkBody ? 'base-white-70' : 'base-black-70'} mt-20 mr-32`}
                                                       style={{
                                                           opacity: this.state.selectedIndex === i ? 1 : 0,
                                                           transition: 'opacity 300ms ease-in-out'
                                                       }}
                                                    >
                                                        {CAPABILITIES[i].layman}
                                                    </p>
                                                </FadeInOnScroll>
                                            </CollapseComponent>
                                        </li>
                                    )
                                }
                            )}
                        </ul>
                        {!this.props.mobile &&
                            <div className='flex-1 col-plain p-40 services-section-right'
                                 style={{borderColor: this.props.darkBody && '#ffffff40'}}>
                                <div
                                    style={{
                                        transition: '150ms ease-in-out',
                                        borderTopLeftRadius: (this.state.selectedIndex === 0 || this.state.selectedIndex === 1) && '20vw',
                                        borderBottomLeftRadius: (this.state.selectedIndex === 0 || this.state.selectedIndex === 1) && '20vw',
                                        borderBottomRightRadius: (this.state.selectedIndex === 2 || this.state.selectedIndex === 2) && '20vw',
                                        borderTopRightRadius: (this.state.selectedIndex === 1 || this.state.selectedIndex === 2) && '20vw',
                                        border: '2px solid white',
                                        overflow: "hidden"
                                    }}
                                    className='flex-1 mb-12 col-je'>
                                    <img alt={''} src={require('../img/patterns/web.png')} className=''
                                         style={{width: "100%", objectFit: "contain", opacity: .6}}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
