import React from "react";

class MousePositionWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
        };
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseMove(event) {
        const {left, top, width, height} = event.target.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;
        const percentX = (x / width) * 100;
        const percentY = (y / height) * 100;
        this.setState({
            x: percentX,
            y: percentY,
        });
        this.props.handleMouseMove(x, y)
    }

    render() {
        const {children} = this.props;
        const {x, y} = this.state;
        return (
            <div onMouseMove={this.handleMouseMove}>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {x, y})
                )}
            </div>
        );
    }
}

export default MousePositionWrapper;