import React, {Component} from "react";
import QuickLink from "./QuickLink";
import TiltEffect from "./wrappers/TiltEffect";
import {Link, withRouter} from "react-router-dom";
import {verticals} from "../utils/dummy";
import SvgArrowRight from "../icons/SvgArrowRight";

class VerticalDualCard extends Component {
    state = {
        hovering: true
    }

    render() {
        return (
            <TiltEffect
                scale={{
                    x: .1,
                    y: .01
                }}
            >
                <div
                    onMouseEnter={() => this.setState({hovering: true})}
                    onMouseLeave={() => this.setState({hovering: false})}
                    className={`flex-1 vertical-card ${this.props.mobile ? 'col-plain' : 'row-plain'} relative`}
                    style={{
                        padding:this.props.mobile ? 20 : 32,
                        alignItems: "stretch"
                    }}>
                    <div className='mr-12' style={{flex: .6, marginBottom: this.props.mobile && 20}}>
                        <img src={require('../img/logos/SStudio.png')}
                             style={{
                                 height: this.props.mobile ? 64 : 80,
                                 marginRight: "auto",
                                 zIndex: 3,
                             }}/>
                        <p className={'heading3 mt-8'}>
                            Scope Studio
                        </p>
                        <p className='title1 mr-12'>
                            We transform your ideas into reality through development, design, and product management.
                        </p>
                        <div style={{marginTop: this.props.mobile ? -12 : 0, marginBottom: this.props.mobile ? 12 : 0}}>
                            <p className={'body2 m-0'}>
                                <span style={{opacity: .6}}>Have an idea you want validated?</span>
                                <Link to={'/contact'} className={'base-black-100 ml-4 hover-underline'}>
                                    <span
                                        className={'body2-bold'}>Contact us</span> <SvgArrowRight width={20} height={20}
                                                                                                  style={{
                                                                                                      transform: 'rotate(-45deg)',
                                                                                                      marginBottom: -4
                                                                                                  }}/>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className={'mr-40'} style={{width: 2, display: "flex", backgroundColor: "#00000010"}}/>
                    <div className={`${this.props.mobile ? 'row-plain' : 'row-plain'} flex-1`}>
                        {[this.props.item1, this.props.item2].map((item, index) =>
                            <div key={item.title} className={'col-jb'} style={{marginRight: index === 0 && 40}}>
                                <div>
                                    <img src={item.img}
                                         style={{
                                             height: this.props.mobile ? 32 : 50,
                                             marginRight: "auto",
                                             zIndex: 3,
                                         }}/>
                                    <div className=' col-plain' style={{alignItems: 'flex-start'}}>
                                        <p className={`${this.props.mobile ? 'body1-bold' : 'heading4'} m-0 mt-12`}>
                                            {item.title}
                                        </p>
                                        {item.new &&
                                            <div className='absolute' style={{top: 20, right: 20}}>
                                                <p className='highlight body1-bold mr-a'>
                                                    NEW
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    <p className={`${this.props.mobile ? 'body2-bold ' : 'body1-bold '}mt-12 mb-0 base-black-70`}>
                                        {item.description}
                                    </p>
                                </div>
                                <div className='row-ac-jc wrap mt-32 cta' style={{gap: 24}}>
                                    {item.primaryLink ?
                                        <a href={'https://hello.scopelabs.com/'} target={'_blank'}
                                           className='primary-button flex-1 body1-bold'
                                           style={{
                                               cursor: item.title === 'The Lab' && 'not-allowed',
                                               opacity: item.title === 'The Lab' && .5
                                           }}
                                        >
                                            {item.buttonLabel}
                                        </a>
                                        :
                                        <Link to={item?.primaryRoute} disabled={!item.route}
                                              className='primary-button flex-1 body1-bold'
                                        >
                                            {item.buttonLabel}
                                        </Link>
                                    }
                                    {/*<div className='row-ac' style={{*/}
                                    {/*    gap: 8,*/}
                                    {/*    opacity: item.title === 'The Lab' && 0,*/}
                                    {/*    pointerEvents: item.title === 'The Lab' && "none"*/}
                                    {/*}}>*/}
                                    {/*    <Link to={item.route}*/}
                                    {/*          disabled={!item.route}*/}
                                    {/*          className='secondary-button body1-bold'*/}
                                    {/*    >*/}
                                    {/*        Learn more*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </TiltEffect>
        )
    }
}

export default withRouter(VerticalDualCard)
