import jackphifer from '../img/people/jackphifer.jpeg'
import jasonpolan from '../img/people/jasonpolan.png'
import jason1 from '../img/people/jason1b.jpeg'
import jason2 from '../img/people/jason2b.jpeg'
import jason3 from '../img/people/jason3b.jpeg'
import jason4 from '../img/people/jason4b.jpeg'
import js1 from '../img/people/js1.jpg'
import js2 from '../img/people/js2.jpg'
import js4 from '../img/people/js4.jpg'
import js5 from '../img/people/js5.jpg'
import jp1 from '../img/people/jp1.jpg'
import jp2 from '../img/people/jp2.jpg'
import jp3 from '../img/people/jp3.jpg'
import jp4 from '../img/people/jp4.jpg'
import coryoshea from '../img/people/coryoshea.jpg'
import co1 from '../img/people/co1.jpg'
import co2 from '../img/people/co2.jpg'
import co3 from '../img/people/co3.jpg'
import co4 from '../img/people/co4.jpg'
import dave from '../img/people/dave.png'
import dave1 from '../img/people/dave1.png'
import dave2 from '../img/people/dave2.png'
import dave3 from '../img/people/dave3.png'
import dave4 from '../img/people/dave4.png'
import jackstrachan from '../img/people/strachan2.jpg'
import Shape64 from "../icons/pack1/Shape64";
import Shape32 from "../icons/pack1/Shape32";
import Shape54 from "../icons/pack1/Shape54";
import Shape5 from "../icons/pack1/Shape5";
import Shape74 from "../icons/pack1/Shape74";
import Shape85 from "../icons/pack1/Shape85";
import Shape3 from "../icons/pack1/Shape3";
import Shape34 from "../icons/pack1/Shape34";
import Shape63 from "../icons/pack1/Shape63";
import Shape40 from "../icons/pack1/Shape40";
import Shape21 from "../icons/pack1/Shape21";
import SvgTime from "../icons/custom/SvgTime";
import SvgProduct from "../icons/custom/SvgProduct";
import SvgWebsite from "../icons/custom/SvgWebsite";
import SvgEditRow from "../icons/custom/SvgEditRow";
import SvgCalendar from "../icons/custom/SvgCalendar";
import SvgBroadcasting from "../icons/custom/SvgBroadcasting";
import SvgWorkflow from "../icons/custom/SvgWorkflow";
import SvgImprovement from "../icons/custom/SvgImprovement";
import SvgPaint from "../icons/custom/SvgPaint";

export const CAPABILITIES = [
    {
        number: '01',
        title: 'Strategy',
        layman: 'We believe in strategy when collaborating on new initiatives. We aim to optimize your product, your time, and your budget.',
        highlights: ['UI/UX', 'User Journey', 'Product Health', 'Competitive Analysis']
    },
    {
        divider: true,
        number: '01',
        title: 'Strategy',
        layman: 'We believe in strategy when collaborating on new initiatives. We aim to optimize your product, your time, and your budget.',
        highlights: ['UI/UX', 'User Journey', 'Product Health', 'Competitive Analysis']
    },
    {
        number: '02',
        title: 'Design',
        layman: 'Our experienced design team is dedicated to crafting a style and image that suits your brand and goals.',
        highlights: ['Web and Mobile Apps', 'User Journeys', 'UI/UX', 'Product']
    },
    {
        divider: true,
        number: '01',
        title: 'Strategy',
        layman: 'We believe in strategy when collaborating on new initiatives. We aim to optimize your product, your time, and your budget.',
        highlights: ['UI/UX', 'User Journey', 'Product Health', 'Competitive Analysis']
    },
    {
        number: '03',
        title: 'Implementation',
        layman: 'We never compromise on quality. Our strategic thinking, meticulous design, and rigorous engineering result in a product that stands alone.',
        highlights: []
    },

]

export const TEAM = [
    {
        name: 'Jack Phifer',
        title: 'Founder / Chief Executive Officer',
        bio: 'With over a decade of experience building successful startups and digital products, Jack has a proven track record of scaling companies and delivering impact. During Jack’s career he has built and deployed apps and websites collectively serving 100k+ users. After his time studying Computer Science at MIT, Jack founded Finary and raised $3M+ in VC funding from top funds such as YCombinator and Upfront Ventures.',
        bio2: 'Outside of work, Jack enjoys spending time outdoors fishing, golfing, and skiing. He also loves spending time with his family and friends back in the Florida sun.',
        img: jackphifer,
        extraImgs: [jp1, jp2, jp3, jp4]
    },
    {
        name: 'Jack Strachan',
        title: 'Founder / Chief Design Officer',
        bio: 'Jack is a frontend expert with experience in digital product development, design, and strategy. Jack\'s expertise spans the full product lifecycle from strategy and design to development and implementation with a primary focus on UI/UX. Before his time at MIT, Jack ran his own YouTube channel that amassed over 1.3M subscribers and a quarter billion total views. Most recently, he worked at Finary.',
        bio2: 'Outside of work, Jack is an aspiring plant dad and avid sweet tea drinker. He enjoys watching sunsets and putting balsamic vinaigrette on almost anything. He also loves beating the above Jack on FIFA.',
        img: jackstrachan,
        extraImgs: [js4, js1, js2, js5]

    },
    {
        name: "Cory O'Shea",
        title: 'Founder / Chief Technology Officer',
        bio: 'Cory is an engineer and entrepreneur with extensive experience in full-stack development and project management across diverse industries. He has contributed his expertise to top-tier tech companies such as Google and Scale AI, leveraging a degree in Computer Science and Engineering from MIT. Throughout his career, Cory has developed robust infrastructure that serves millions of users. During the pandemic, he helped found, build, and scale a tutoring company that was eventually acquired.',
        bio2: 'Outside of work, Cory likes to spend his free time traveling, skiing, and hanging out with friends and family. He is also looking to get into golf, so if you have any pointers send them his way.',
        img: coryoshea,
        extraImgs: [co1, co2, co3, co4]
    },
    {
        name: "Jason Polan",
        title: 'Chief Operating Officer',
        bio: 'Jason is a leader with over 20 years of experience delivering impactful results.   As an Operations expert Jason has worked both as an Entrepreneur building his own businesses but also a leader in a the corporate world.  Throughout his career, he has managed the successful execution of multiple large scale projects for many brands including: WWE, Coca-Cola, NASCAR, Oprah Winfrey Network, Facebook and many more.  As a leader, Jason prides himself in his ability to master any project and has a passion for building.',
        bio2: 'Outside of work, Jason and his family enjoy an active outdoor lifestyle. He can typically be found skiing the slopes, cheering on his kids at soccer games, or tackling a hike up the nearest mountain trail.',
        img: jasonpolan,
        extraImgs: [jason1, jason2, jason3, jason4]
    },
    {
        name: "Dave Sukoff",
        title: 'Chief Strategy Officer',
        bio: 'With over three decades in technology and finance, Dave has founded and strategically grown multiple companies. As a national programming champion, he studied finance and economics at MIT, developed machine learning tools, and founded a hedge fund, scaling it from scratch to $500 million. He then founded an MIT software startup, creating software to control and distribute media across networked devices, and is an inventor on four awarded patents. Most recently, Dave founded a broker-dealer firm, advising early-stage companies and raising over $250 million.',
        bio2: 'Dave primarily enjoys spending time with his family. He is a fantasy football fanatic, obsessing over every strategic angle. He was the head coach for a boys’ varsity lacrosse team, has been an economic advisor to multiple political campaigns, and written numerous published pieces.',
        img: dave,
        extraImgs: [dave1, dave2, dave3, dave4]
    },
]

export const PROCESS = [
    {
        title: 'Kickoff'
    },
    {
        title: 'Discovery / Strategy'
    },
    {
        title: 'Spec Creation / Roadmapping'
    },
    {
        title: 'Iteration'
    },
    {
        title: 'Maintenance'
    },
]

export const CASE_STUDY = {
    brand: 'Starfish',
    budget: 3,
    industry: 'Consumer Social',
    location: "Pasadena, CA",
    environment: ['React', 'React Native'],
    release: 2023,
    colors: ['#032549', '#166DB9'],
    live: {
        link: '/',
        text: 'Download app'
    },
    summary: [
        {
            label: 'Problem',
            text: "Starfish approached Scope with a need for a team that could bring their vision of 'social media for good' to life.",
            list: [
                {
                    prepend: '01.',
                    body: 'There was no real place for someone to showcase their humanitarian persona'
                },
                {
                    prepend: '02.',
                    body: 'It was difficult for non-profits to connect with younger generations'
                },
                {
                    prepend: '03.',
                    body: 'Awareness happened at a different location than actual fundraising'
                }
            ]
        },
        {
            label: 'Solution',
            text: 'We deployed a multi functional solution built from the ground up by creating a social media platform with key differentiating pieces:',
            list: [
                {
                    prepend: '‣',
                    body: 'Non profit profiles that allow awareness and fundraising to happen in the same place'
                },
                {
                    prepend: '‣',
                    body: 'A robust database of 1.8M non-profits that allows users to find and connect with the causes that matter'
                },
                {
                    prepend: '‣',
                    body: 'A novel fundraising system called “Starfish Challenges”, a gamified and social oriented solution to fundraising'
                }
            ]
        },
        {
            label: "Scope's Role",
            text: 'Initially, Scope was contracted to develop the v1 platform. After this, an agreement was reached to increase capacity and Scope was tasked with taking on broader product and design challenges for Starfish.',
        }
    ],
    content: [
        {
            title: 'Releasing V1',
            text: 'The first order of business was to re-skin the existing app. In the process, we implemented key pieces to build upon with future iterations. Starfish v1 was a visual and functional overhaul that included:',
            list: [
                {
                    prepend: '‣',
                    body: 'A feature rich social media experience with tags, mentions, attachments, and more'
                },
                {
                    prepend: '‣',
                    body: 'A database of 5,000 of the largest non-profits'
                },
                {
                    prepend: '‣',
                    body: 'Our new notion of an institutional profile for NPOs'
                }
            ]
        },
        {
            title: 'Releasing V2',
            text: 'After determining the baseline for success with v1, v2 was all about upgrading feature set and defining the brand. In weeks of intense build we implemented a new design system, built a new web splash page, and added new features to the platform including:',
            list: [
                {
                    prepend: '‣',
                    body: 'Advanced data for 1.8M NPO profiles with claiming for admin ownership'
                },
                {
                    prepend: '‣',
                    body: 'An intelligent non-profit and user recommender'
                },
                {
                    prepend: '‣',
                    body: 'In app onboarding and new user experience'
                },
                {
                    prepend: '‣',
                    body: 'Push + email notifications'
                },

            ]
        },
        {
            title: 'Design System and Brand Guidelines',
            text: 'The new Starfish branding is as warm and welcoming as the community it represents. Bright and coral-like accents pay homage to the name. The lighter look and new colors maintain the same playful theme, while allowing proper accenting within the app. Karla is a gold standard of typography that pairs well with the essence of the brand.',
        },
        {
            title: 'Profiles for Users and Non-Profits',
            text: 'We built profiles to give users the freedom to express themselves and highlight the causes they care about. Each organization page acts as a hub to provide more information about the cause and highlight user-generated content and conversations to explore. These profiles can be claimed and managed by the organization.',
        },
        {
            title: 'Robust Non-Profit Database Integration',
            text: 'Starfish connects users with over 1.8M non-profit organizations. We designed a robust search so that users will be able to find exactly what they’re looking for. With the roll-out of the intelligent recommender, the Starfish App will suggest new causes to users that they may be interested in allowing a novel way to find new causes to support.',
        },
        {
            title: 'Rich Text Posts With Attachments',
            text: 'Users demand engaging social platforms capable of handling all kinds of discourse, discovery, and engagement. We wanted to ensure these needs were met and exceeded by designing posts to handle tagging, mentions, image and video attachments, link embeds, and more.',
        },
        {
            title: 'Splash Page Redesign',
            text: 'Starfish’s new website is a complete overhaul from content to design. We wanted to focus on the user, and the website serves as the elevator pitch inviting them to join the fun. It’s lightweight and easy-to-read, while conveying everything needed. Tasteful animations and modern UI/UX solutions act as the cherry on top.',
        },
        {
            title: 'Whats Next',
            text: 'The next phase of development centers around two main initiatives, Starfish Balance and Starfish Challenges. With balance, users will be able to deposit money into their account to hold and eventually donate to their causes of choice directly within the app. Starfish Challenges are time sensitive campaigns designed to drive fundraising and awareness. These features will serve as the cornerstone of the newest iteration of Starfish, and bring to life the vision of NPOs raising awareness and funds in the same location.',
        },
    ]
}

export const RECRUIT_CASE_STUDY = {
    brand: 'Recruit U',
    title: "RecruitU",
    budget: 3,
    industry: '--',
    location: "New York, NY",
    type: ['Zero to One', 'Studio'],
    tagline: 'Revitalizing recruiting for a new generation of job seekers and recruiters',
    environment: ['React'],
    release: 'Sept 2023',
    colors: ['#4848F2', '#3434b6'],
    live: {
        link: '/',
        text: 'Live site'
    },
    summary: [
        {
            label: 'The Idea',
            text: "RecruitU came to Scope Labs with an idea: one centralized platform to optimize the finance recruiting process, for both job seekers and recruiters. RecruitU was seeking to solve a two-fold problem:",
            list: [
                {
                    prepend: '‣',
                    body: 'Undergraduate students lacked centralized tools to effectively manage and keep track of their job search, leading to ineffective networking and disorganized application tracking.'
                },
                {
                    prepend: '‣',
                    body: 'Recruiters faced a firehose of irrelevant applications, resulting in inefficient recruitment and difficult talent matching.'
                },
            ]
        },
        {
            label: 'The Partnership',
            text: 'RecruitU teamed up with Scope to pioneer the first career building and recruitment platform that brokers direct connections for students and recruiters alike to streamline the job search and hiring process. During their Zero to One partnership, Scope provided RecruitU:',
            list: [
                {
                    prepend: '‣',
                    body: 'a brand refresh'
                },
                {
                    prepend: '‣',
                    body: 'a cold start strategy'
                },
                {
                    prepend: '‣',
                    body: "a research-backed web platform"
                },
                {
                    prepend: '‣',
                    body: "a splash page overhaul"
                },
                {
                    prepend: '‣',
                    body: "a pitch deck"
                },
                {
                    prepend: '‣',
                    body: "seasoned fundraising advice"
                },
            ]
        },
        {
            label: "The Solution",
            text: 'Over 12 weeks, Scope worked closely with Recruit U to turn their idea into a consumer-facing reality that’s fundraised $1M to date. The platform now empowers:',
            list: [
                {
                    prepend: '‣',
                    body: 'Undergraduate students to organize the entire recruitment process in one place, from application tracking capabilities to networking groups.'
                },
                {
                    prepend: '‣',
                    body: 'Recruiters to directly connect with undergraduate finance clubs to access pre-vetted applicants.'
                },
            ]
        }
    ],
    content: [
        {
            label: '01',
            title: 'Scope and Strategize',
            text: 'RecruitU aimed to shift the way undergraduate finance recruiting is conducted, to streamline the process for undergrads and recruiters alike. Their thesis: undergraduate recruiting should be driven by undergraduate finance clubs, which can consolidate job-seeking resources for members and offer recruiters pre-vetted candidates. They just needed a platform.',
            text2: "So, they enlisted Scope — and together, they scoped out a complete build-to-fundraise strategy and a three-month timeline for completion.",
        },
        {
            title: 'Discovery',
            text: "To tackle RecruitU’s cold start problem, Scope engaged with target audiences and learned their pain points to determine what features could provide value right out of the gate — to convince students and recruiters alike to ditch their existing systems and turn to RecruitU’s all-in-one platform.",
        },
        {
            label: '02',
            title: 'Design',
            text: "Scope developed a tech-forward, futuristic brand identity for RecruitU, including a new logo, graphic elements, and UI/UX, for a clean, modern, and streamlined feel. Departing from the sector’s conventional light blue, Scope selected a sleek indigo, slate, and white color palette to highlight a sense of innovation and freshness. RecruitU’s branding refresh signals a departure from conventional job seeking to dynamic career building in finance.",
        },
        {
            label: '03',
            title: 'Develop',
            text: "RecruitU wanted to trim the fat plaguing traditional job listing platforms and declutter the application process. With students and recruiters in mind, Scope designed and built proprietary application and networking tools to manage the entire financial recruitment process in a singular platform. The pioneering platform brokers direct connections between pre-vetted undergraduate applicants and recruiters, helps students keep their applications organized, and enables student organizations to grow their alumni network. Plus, the platform seamlessly integrates with LinkedIn, enabling easy profile setup.",
        },
        {
            label: '04',
            title: 'Test',
            text: "Scope refreshed RecruitU’s splash page to reflect the brand’s new identity before opening a waitlist and allowing users to beta test the platform. After iterating with users, the rebranded and fully operational RecruitU platform was ready to be unleashed.",
        },
        {
            label: '05',
            title: 'Ship & Fundraise ',
            text: "Drawing from the team’s seasoned fundraising experience, Scope provided RecruitU a custom, comprehensive pitch deck — and in just six weeks, RecruitU has raised $1M and grown to more than 5,000 users.",
        },
        {
            title: 'Continuing Partnership',
            text: 'After Zero to One, RecruitU is continuing to work with Scope Labs in Scope Studio. Scope and RecruitU have embarked together on onboarding additional student organizations, winning more recruiter customers, and fundraising additional capital to continue growing RecruitU’s business — revolutionizing the job search and career building process for thousands of users.',
        },
        {
            title: 'Continuing Partnership',
            text: "Rea is now poised for an exciting journey ahead. With the polished app and website ready to shine, Rea is gearing up for a public launch to introduce itself to the world. Armed with the new app, website, and custom assets we've created, Rea is set to approach investors for further funding, and plans to partner with Scope for future product, design, and development down the road.",
        },
    ]
}


export const EPIC_CASE_STUDY = {
    brand: 'Epic Photo Ops',
    budget: 3,
    industry: 'Entertainment',
    location: "Everett, WA",
    type: ['Studio'],
    tagline: 'A better celebrity photo op experience',
    environment: ['React', 'Nextjs', 'Python'],
    release: 'Sept 2023',
    colors: ['#F66A20', '#af4710'],
    live: {
        link: '/',
        text: 'Live site'
    },
    summary: [
        {
            label: 'The Problem',
            text: "Epic Photo Ops connects fans with their favorite celebrities and captures the moment in high-quality memento photographs. Massive demand for Epic’s photo services at events like ComicCon and Fan Expo meant long lines of fans waiting to snap a photograph with their favorite celebrities. To provide the highest quality photograph to customers, up to four Epic Photo Ops employees had to crop all photos by hand before they could be printed and handed out — which made long lines even longer.",
        },
        {
            label: 'The Solution',
            text: 'Epic Photo Ops enlisted Scope to alleviate the bottleneck. Scope’s solution: a custom computer-vision model. Taking 15 years of pre- and post-cropped photos, Scope built and trained the model on that dataset to intelligently suggest where and how photos should be cropped.',
        },
        {
            label: "The Implementation",
            text: 'The trained model made Epic Photo Ops massively more efficient: Instead of taking four employees to crop all photos by hand, Epic Photo Ops now only needed one person to approve the model’s suggested crops. Not only did Scope’s computer-vision model cut down on staffing costs, it also eliminated the cropping bottleneck, enabling Epic Photo Ops to serve more customers much more quickly.',
        },
        {
            label: "Additional",
            text: 'Scope also helped Epic refresh their old website and overhaul it with a fun and modern feel to showcase their new brand.',
        }
    ],
}

export const REA_CASE_STUDY = {
    brand: 'Rea',
    title: "Rea",
    budget: 3,
    industry: 'Consumer Rewards',
    tagline: 'Seamlessly consolidating forgotten points into one dashboard',
    location: "Pasadena, CA",
    environment: ['React Native', 'Framer', 'Figma'],
    release: 'In Beta',
    colors: ['#0A3736', '#0d3b36'],
    live: {
        link: '/',
        text: 'Download app'
    },
    summary: [
        {
            label: 'The Idea',
            text: "Rea enlisted Scope Labs to turn their idea — aggregating rewards points from multiple programs in one place for users to easily redeem — into a functioning app.",
        },
        {
            label: 'The Partnership',
            text: 'On an expedited one-month timeline, Scope teamed up with Rea to build the most value in the shortest amount of time: a single dashboard for a user’s points that allows them to view, track, and use their rewards across multiple programs.'
        },
        {
            label: 'The Solution',
            text: 'Scope worked closely with Rea to turn their idea into a consumer-facing app that empowers users to consolidate their rewards points in one central location, to better keep track of — and redeem — their points.',
        },
    ],
    content: [
        {
            label: '01',
            title: 'Scope & Strategize',
            text: 'Working within a set budget and condensed four-week timeline, Scope’s one-stop-shop team worked with Rea to scope out a brand overhaul and a complete app build to provide the most value quickly.',
        },
        {
            title: 'Discovery',
            text: "After engaging with users to determine what features would provide them the most value, Scope recommended a unique dashboard that could aggregate points from multiple rewards programs — linking all rewards in one place for easy monitoring and redeeming.",
        },
        {
            label: '02',
            title: 'Design',
            text: "To elevate Rea above conventional points tracking apps, Scope crafted a new brand for Rea that infuses a sense of refined luxury into the world of rewards. The metallic elements convey exclusivity and a sense of premium quality, while dynamic green accents symbolize growth and vitality. Scope provided Rea a complete branding package, including a distinctive icon, a complementary wordmark logo, comprehensive brand guidelines, and a suite of custom assets.",
        },
        {
            label: '03',
            title: 'Develop',
            text: "In two weeks, Scope built an app enabling users to integrate their rewards accounts in one place, allowing them to track, search, and redeem points across multiple programs with ease.",
        },
        {
            label: '04',
            title: 'Test',
            text: "After undergoing beta testing, Scope created a catchy splash page for Rea to reflect the brand’s new identity — and the fully operational Rea app was ready for consumers after just a few weeks.",
        },
        {
            label: '05',
            title: 'Ship & Fundraise ',
            text: "Drawing from the team’s seasoned fundraising experience, Scope provided Rea a custom, comprehensive pitch deck — and in just one month from conception to creation, Rea is now poised to raise a pre-seed extension.",
        },
        {
            title: 'Continuing Partnership',
            text: 'After Zero to One, Rea is continuing to work with Scope Labs in Scope Studio. Scope and Rea have embarked together to grow Rea’s business by fundraising additional capital, continuing beta testing, building out expanded functionality for the platform to empower users to get the most value from their rewards points.',
        },
    ]
}

export const EVOLVE_CASE_STUDY = {
    brand: 'Evolve',
    title: "Evolve",
    budget: 3,
    industry: 'Consumer Rewards',
    tagline: 'AR and Web3 powered social',
    location: "Pasadena, CA",
    environment: ['React Native', 'Framer'],
    release: 'Sept 2023',
    colors: ['#941cda', '#80bbff'],
    live: {
        link: '/',
        text: 'Download app'
    },
    summary: [
        {
            label: 'The Idea',
            text: "Rea enlisted Scope Labs to turn their idea — aggregating rewards points from multiple programs in one place for users to easily redeem — into a functioning app.",
        },
        {
            label: 'The Partnership',
            text: 'On an expedited one-month timeline, Scope teamed up with Rea to build the most value in the shortest amount of time: a single dashboard for a user’s points that allows them to view, track, and use their rewards across multiple programs.'
        },
        {
            label: 'The Solution',
            text: 'On an expedited one-month timeline, Scope teamed up with Rea to build the most value in the shortest amount of time: a single dashboard for a user’s points that allows them to view, track, and use their rewards across multiple programs.',
        },
    ],
    content: [
        {
            title: 'Scope & Strategize',
            text: 'Working within a set budget and condensed four-week timeline, Scope’s one-stop-shop team worked with Rea to scope out a brand overhaul and a complete app build to provide the most value quickly.',
        },
        {
            title: 'Discovery',
            text: "After engaging with users to determine what features would provide them the most value, Scope recommended a unique dashboard that could aggregate points from multiple rewards programs — linking all rewards in one place for easy monitoring and redeeming.",
        },
        {
            title: 'Design',
            text: "To elevate Rea above conventional points tracking apps, Scope crafted a new brand for Rea that infuses a sense of refined luxury into the world of rewards. The metallic elements convey exclusivity and a sense of premium quality, while dynamic green accents symbolize growth and vitality. Scope provided Rea a complete branding package, including a distinctive icon, a complementary wordmark logo, comprehensive brand guidelines, and a suite of custom assets.",
        },
        {
            title: 'Develop & Test',
            text: "In two weeks, Scope built an app enabling users to integrate their rewards accounts in one place, allowing them to track, search, and redeem points across multiple programs with ease.",
        },
        {
            text: "After undergoing beta testing, Scope created a catchy splash page for Rea to reflect the brand’s new identity — and the fully operational Rea app was ready for consumers after just a few weeks.",
        },
        {
            title: 'Ship & Fundraise ',
            text: "Drawing from the team’s seasoned fundraising experience, Scope provided Rea a custom, comprehensive pitch deck — and in just one month from conception to creation, Rea is now poised to raise $300k.",
        },
        {
            title: 'Continuing Partnership',
            text: 'After Zero to One, Rea is continuing to work with Scope Labs in Scope Studio. Scope and Rea have embarked together to grow Rea’s business by fundraising additional capital, continuing beta testing, building out expanded functionality for the platform to empower users to get the most value from their rewards points.',
        },
    ]
}

export const GLOSSI_CASE_STUDY = {
    brand: 'Glossi',
    title: "Glossi",
    budget: 3,
    industry: 'Consumer Rewards',
    tagline: 'Create stunning 3D renders in minutes',
    location: "Los Angeles, CA",
    environment: ['React'],
    release: 'Q1 2024',
    colors: ['#FE5400', '#FDBE00'],
    live: {
        link: '/',
        text: 'Visit site'
    },
    summary: [
        {
            label: 'The Problem',
            text: "Glossi is the first real-time virtual studio built for e-commerce, enabling users to create stunning 3D renders for eye-catching marketing videos in minutes. With just a 3D product model, users can use Glossi to create highly customizable studio-quality videos and photos on their own browsers, eliminating the need for costly physical production studios.",
            text2: "However, a confusing and slow front end meant few users were able to take full advantage of Glossi’s revolutionary tech. Users were getting bogged down in Glossi’s complex, technical website — and were prevented from fully realizing the potential of Glossi’s game-changing virtual studio."
        },
        {
            label: 'The Partnership',
            text: 'Glossi enlisted Scope Labs to strategize how to best tell Glossi’s story and technology as efficiently as possible. Scope listened to users’ pain points and feedback on the old site and formulated a plan to overhaul and streamline Glossi’s front end to enhance the customer experience. Drawing from the team’s seasoned experience, Scope walked Glossi through a design overhaul and streamlined user journeys to ensure users could grasp Glossi’s cutting-edge offerings as quickly as possible.'
        },
        {
            label: 'The Solution',
            text: 'Incorporating feedback from users, Scope delivered Glossi a massively streamlined platform that puts Glossi’s innovative tech front and center for users to understand. The faster website that Scope built also means users can experience increased functionality at their fingertips, revolutionizing product visual creation.',
        },
    ],
    content: [
        {
            title: 'Scope & Strategize',
            text: 'Working within a set budget and condensed four-week timeline, Scope’s one-stop-shop team worked with Rea to scope out a brand overhaul and a complete app build to provide the most value quickly.',
        },
        {
            title: 'Discovery',
            text: "After engaging with users to determine what features would provide them the most value, Scope recommended a unique dashboard that could aggregate points from multiple rewards programs — linking all rewards in one place for easy monitoring and redeeming.",
        },
        {
            title: 'Design',
            text: "To elevate Rea above conventional points tracking apps, Scope crafted a new brand for Rea that infuses a sense of refined luxury into the world of rewards. The metallic elements convey exclusivity and a sense of premium quality, while dynamic green accents symbolize growth and vitality. Scope provided Rea a complete branding package, including a distinctive icon, a complementary wordmark logo, comprehensive brand guidelines, and a suite of custom assets.",
        },
        {
            title: 'Develop & Test',
            text: "In two weeks, Scope built an app enabling users to integrate their rewards accounts in one place, allowing them to track, search, and redeem points across multiple programs with ease.",
        },
        {
            text: "After undergoing beta testing, Scope created a catchy splash page for Rea to reflect the brand’s new identity — and the fully operational Rea app was ready for consumers after just a few weeks.",
        },
        {
            title: 'Ship & Fundraise ',
            text: "Drawing from the team’s seasoned fundraising experience, Scope provided Rea a custom, comprehensive pitch deck — and in just one month from conception to creation, Rea is now poised to raise $300k.",
        },
        {
            title: 'Continuing Partnership',
            text: 'After Zero to One, Rea is continuing to work with Scope Labs in Scope Studio. Scope and Rea have embarked together to grow Rea’s business by fundraising additional capital, continuing beta testing, building out expanded functionality for the platform to empower users to get the most value from their rewards points.',
        },
    ]
}

export const AIGENTLESS_CASE_STUDY = {
    brand: 'Aigentless',
    title: "Aigentless",
    budget: 3,
    industry: 'Residential Leasing',
    tagline: 'The first platform for agentless leasing',
    location: "Chicago, IL",
    environment: ['React', 'React Native'],
    release: 'Q3 2024',
    colors: ['#D2CDCB', '#8E7766'],
    live: {
        link: 'https://aigentless.com',
        text: 'Visit site'
    },
    summary: [
        {
            label: 'The Problem',
            text: "Aigentless envisioned a revolutionary way to approach property leasing — a fully autonomous, agent-free platform that would allow property managers to streamline leasing processes, engage renters, and offer flexible, secure, on-demand showings.",
            text2: "But they had only an idea. To bring it to life, they needed a partner who could handle everything from initial product development to branding and UX design. Aigentless sought a platform where property managers could list properties, create interactive virtual tours, and access powerful AI-driven insights, all while keeping the experience user-friendly and highly secure."
        },
        {
            label: 'The Partnership',
            text: 'Aigentless partnered with Scope Labs to build the entire platform from the ground up. Scope worked collaboratively with Aigentless to shape and define every aspect of the product, translating Aigentless’s vision into a complete MVP with a sleek, intuitive design and cutting-edge functionalities.',
            text2: 'From creating the brand identity to implementing a robust backend and developing an engaging frontend, Scope was there every step of the way. We drew on our technical expertise and design experience to ensure Aigentless’s vision was realized in a platform that was easy to use, innovative, and positioned to transform the rental market.'
        },
        {
            label: 'The Solution',
            text: 'Scope delivered a fully functional platform that allows property managers to list properties, create immersive virtual tours, and offer renters on-demand, self-guided tours with 24/7 AI-Gent support. With a secure, three-layer biometric access system, renters can book and tour properties anytime, even after hours. The platform also provides property managers with powerful analytics and AI-driven insights, helping them optimize listings and improve conversion rates.',
            text2: 'From the branding to the technology, Scope built Aigentless’s platform to make autonomous leasing accessible and efficient. With the platform in place, Aigentless can now provide unparalleled value to both property managers and renters, streamlining leasing, reducing overhead, and setting a new standard in property leasing automation.',
        },
    ],
}

export const SEATCLUB_CASE_STUDY = {
    brand: 'SeatClub',
    title: "SeatClub",
    budget: 3,
    industry: 'Ticketing & Events',
    tagline: 'The first and only subscription based ticket marketplace',
    location: "Boca Raton, FL",
    environment: ['React', 'React Native'],
    release: 'Q3 2024',
    colors: ['#48B3B3', '#36264A'],
    live: {
        link: 'https://seatclub.com',
        text: 'Visit site'
    },
    summary: [
        {
            label: 'The Problem',
            text:'SeatClub was a game-changer in the ticketing industry, as the first subscription-based ticket marketplace that prioritized fans over profits. Unlike traditional platforms, SeatClub offered a transparent, affordable ticketing experience by cutting out markups and hidden fees. However, the platform struggled to deliver on its revolutionary promise.',
            text2:'With an outdated and feature limited website, SeatClub had trouble competing with the industry’s bigger players. Users found the site difficult to use and were unable to fully enjoy the advantages of SeatClub’s unique value proposition. To fulfill its vision, SeatClub needed a complete digital transformation.'
        },
        {
            label: 'The Partnership',
            text:"SeatClub partnered with Scope Labs to overhaul its website and app, creating a more intuitive, engaging, and feature-rich platform. Scope approached the project holistically, listening to SeatClub’s vision and analyzing user feedback to understand exactly where improvements were needed. We set out to reimagine SeatClub’s digital experience from the ground up, ensuring that the new platform would not only look great but would also enhance functionality and support SeatClub’s mission to bring fair ticket prices to fans.",
            text2:'Working closely with SeatClub, we redesigned the entire user interface, modernized the site’s aesthetic, and introduced new features to make the platform more competitive and enjoyable to use. This partnership aimed to deliver a digital experience that matched SeatClub’s innovative business model and empowered fans to find great deals effortlessly.'
        },
        {
            label: 'The Solution',
            text:'Scope Labs delivered a complete facelift for SeatClub’s website and app, transforming the platform into a sleek, user-friendly experience. We integrated key features like interactive maps, allowing users to explore venue layouts and choose seats with confidence. The new dynamic seat views provide a real-time look at views from each section, enhancing transparency and helping users make informed decisions. We also introduced a friend referral program to promote community growth and a deal score system that highlights the best ticket prices for each event.',
            text2:'The revamped SeatClub site now truly reflects the company’s mission: to revolutionize the ticket-buying experience by prioritizing transparency, affordability, and community. With Scope’s help, SeatClub is better equipped than ever to disrupt the traditional ticketing industry, providing fans with an intuitive, enjoyable experience that turns ticket-buying into a pleasure, not a hassle.'
        },
    ],
}

export const STONK_MADNESS_CASE_STUDY = {
    brand: 'Stonk Madness',
    title: "Stonk Madness",
    budget: 3,
    industry: 'Consumer Social',
    tagline: 'Pick the right stocks & win prizes',
    location: "Los Angeles, CA",
    environment: ['React', 'Supabase'],
    release: 'Q2 2024',
    colors: ['#0a825d', '#f0f136'],
    live: {
        link: '/',
        text: 'Visit site'
    },
    summary: [
        {
            label: 'Intro',
            text: "We at Scope Labs were approached by AfterHour to develop a dream project. Stonk Madness. Imagine a March Madness-like bracket, but for stocks. 64 stocks, users pick winners, compete with others, and the best 20 brackets win a prize.",
            text2: "The best part is, if there is a perfect bracket winner, they would win a real Lamborghini. So obviously the Scope team was all about turning this dream into reality. Let’s dive into what it took to bring this dream project to life."
        },
        {
            label: 'The Partnership',
            text: 'The AfterHour team gave us the basics, the outline, the vision and the idea and said, "Make it happen." So we did. We worked very closely with the AfterHour team and we were looking at building a whole new kind of stock bracket system – not just your run-of-the-mill setup. Taking what has existed in the past and taking it to the next level in terms of not only front-end UI and UX but also the backend technology to make this thing shine.'
        },
        {
            label: 'The Solution',
            text: 'We set out to build a net new experience that would elevate Stonk Madness above the rest of the crowd. At its core, Stonk Madness features a custom matchup system that integrates live price data, historical trends, and derivative analysis to provide the most robust insights into each stock of any bracket tournament on the market. We coupled this with a stock companion, Stuey, to provide user with qualitative information and trends from around the web along with hand crafted quips and winner predictions.',
            text2: 'We then integrated with AfterHour’s existing authentication store to streamline user onboarding and efficiently funnel users to the AfterHour app. Finally, we wrapped the whole platform with a redis cache to ensure users got their data without a second of latency.'
        },
    ],
    content: [
        {
            title: 'Scope & Strategize',
            text: 'Working within a set budget and condensed four-week timeline, Scope’s one-stop-shop team worked with Rea to scope out a brand overhaul and a complete app build to provide the most value quickly.',
        },
        {
            title: 'Discovery',
            text: "After engaging with users to determine what features would provide them the most value, Scope recommended a unique dashboard that could aggregate points from multiple rewards programs — linking all rewards in one place for easy monitoring and redeeming.",
        },
        {
            title: 'Design',
            text: "To elevate Rea above conventional points tracking apps, Scope crafted a new brand for Rea that infuses a sense of refined luxury into the world of rewards. The metallic elements convey exclusivity and a sense of premium quality, while dynamic green accents symbolize growth and vitality. Scope provided Rea a complete branding package, including a distinctive icon, a complementary wordmark logo, comprehensive brand guidelines, and a suite of custom assets.",
        },
        {
            title: 'Develop & Test',
            text: "In two weeks, Scope built an app enabling users to integrate their rewards accounts in one place, allowing them to track, search, and redeem points across multiple programs with ease.",
        },
        {
            text: "After undergoing beta testing, Scope created a catchy splash page for Rea to reflect the brand’s new identity — and the fully operational Rea app was ready for consumers after just a few weeks.",
        },
        {
            title: 'Ship & Fundraise ',
            text: "Drawing from the team’s seasoned fundraising experience, Scope provided Rea a custom, comprehensive pitch deck — and in just one month from conception to creation, Rea is now poised to raise $300k.",
        },
        {
            title: 'Continuing Partnership',
            text: 'After Zero to One, Rea is continuing to work with Scope Labs in Scope Studio. Scope and Rea have embarked together to grow Rea’s business by fundraising additional capital, continuing beta testing, building out expanded functionality for the platform to empower users to get the most value from their rewards points.',
        },
    ]
}


export const SHOW_OPEN_CASE_STUDY = {
    brand: 'Show Open Events & Production',
    title: "Show Open",
    budget: 3,
    industry: 'Events & Production',
    tagline: 'Transform your ideal event into reality',
    location: "Dallas / Fort Worth, TX",
    environment: ['Framer'],
    release: 'Q1 2024',
    colors: ['#00ACAA', '#075b59'],
    live: {
        link: '/',
        text: 'Visit site'
    },
    summary: [
        {
            label: 'The Ask',
            text: "ShowOpen's original website, while rich in content and experience, faced a few challenges. In the fast-paced world of event management, having a compelling and functional digital presence is not just beneficial – it's essential.",
            text2: "ShowOpen has always stood out with their exceptional services. But, their digital presence needed a refresh to match their dynamic offline persona. That's where Scope Labs stepped in, bringing our expertise to give ShowOpen’s website a much-needed refresh."
        },
        {
            label: 'The Challenge',
            text: 'The website’s original style did not reflect the modern, dynamic nature of ShowOpen’s services.\n' +
                'With an increasing number of users accessing websites through mobile devices, the non-responsive design hindered user experience and didn’t get their message across the way it should have. Conveying the company\'s extensive services and expertise in a clear and engaging manner was essential. Understanding these challenges, Scope Labs embarked on a journey to revitalize ShowOpen\'s digital front, ensuring the website not only looked aesthetically pleasing but also functioned seamlessly across all devices.'
        },
        {
            label: 'The Solution',
            text: 'Working closely with the ShowOpen team, we delved deep into understanding their brand essence, target audience, and unique selling points. This collaborative approach ensured that the website redesign was not just a cosmetic facelift but a strategic enhancement of ShowOpen’s brand identity.',
            text2: 'The new website design is sleek, modern, and reflective of ShowOpen\'s innovative approach to event management. The redesign focused on articulating ShowOpen’s services and successes in a coherent and captivating manner.',
        },
        {
            label: 'The Outcome',
            text: 'ShowOpen’s new website has received overwhelmingly positive feedback through our testing and feedback process. The updated digital presence not only mirrors the company’s professionalism and expertise but also significantly enhances user engagement. The website now offers an intuitive, user-friendly interface. Built modularly using Framer, it’s easily editable and built for change. The modern design aligns with ShowOpen\'s reputation as a leader in the event management industry.',
            text2: 'This project with ShowOpen exemplifies how a thoughtful and well-executed website redesign can significantly impact a business’s digital presence and client engagement. At Scope Labs, we pride ourselves on creating digital solutions that not only look great but also deliver tangible results. Looking to elevate your digital presence? Let Scope Labs guide you through your digital transformation journey.'
        },
    ],
    // content: [
    //     {
    //         title: 'Scope & Strategize',
    //         text: 'Working within a set budget and condensed four-week timeline, Scope’s one-stop-shop team worked with Rea to scope out a brand overhaul and a complete app build to provide the most value quickly.',
    //     },
    //     {
    //         title: 'Discovery',
    //         text: "After engaging with users to determine what features would provide them the most value, Scope recommended a unique dashboard that could aggregate points from multiple rewards programs — linking all rewards in one place for easy monitoring and redeeming.",
    //     },
    //     {
    //         title: 'Design',
    //         text: "To elevate Rea above conventional points tracking apps, Scope crafted a new brand for Rea that infuses a sense of refined luxury into the world of rewards. The metallic elements convey exclusivity and a sense of premium quality, while dynamic green accents symbolize growth and vitality. Scope provided Rea a complete branding package, including a distinctive icon, a complementary wordmark logo, comprehensive brand guidelines, and a suite of custom assets.",
    //     },
    //     {
    //         title: 'Develop & Test',
    //         text: "In two weeks, Scope built an app enabling users to integrate their rewards accounts in one place, allowing them to track, search, and redeem points across multiple programs with ease.",
    //     },
    //     {
    //         text: "After undergoing beta testing, Scope created a catchy splash page for Rea to reflect the brand’s new identity — and the fully operational Rea app was ready for consumers after just a few weeks.",
    //     },
    //     {
    //         title: 'Ship & Fundraise ',
    //         text: "Drawing from the team’s seasoned fundraising experience, Scope provided Rea a custom, comprehensive pitch deck — and in just one month from conception to creation, Rea is now poised to raise $300k.",
    //     },
    //     {
    //         title: 'Continuing Partnership',
    //         text: 'After Zero to One, Rea is continuing to work with Scope Labs in Scope Studio. Scope and Rea have embarked together to grow Rea’s business by fundraising additional capital, continuing beta testing, building out expanded functionality for the platform to empower users to get the most value from their rewards points.',
    //     },
    // ]
}

export const MOBILE_CAPABILITY_ICONS = [
    {
        icon: <Shape74 width={64 * .7} height={64 * .7}/>,
    },
    {
        icon: <Shape34 width={64 * .7} height={64 * .7}/>,
    },
    {
        icon: <Shape64 width={64 * .7} height={64 * .7}/>,
    },
    {
        icon: <Shape3 width={58 * .7} height={58 * .7}/>,
    },
    {
        icon: <Shape32 width={64 * .7} height={64 * .7}/>,
    },
    {
        icon: <Shape54 width={64 * .7} height={64 * .7}/>,
    },
    {
        icon: <Shape85 width={70 * .7} height={70 * .7}/>,
    },
    {
        icon: <Shape5 width={64 * .7} height={64 * .7}/>,
    }

]

export const FULL_CAPABILITIES = [
    {
        title: 'Frontend',
        icon: <Shape74 width={64} height={64}/>,
        topics: [
            {
                topic: 'Product & UI / UX Design'
            },
            {
                topic: 'Websites and splash pages'
            },
            {
                topic: 'Web and mobile apps'
            },
            {
                topic: 'Tools and dashboards'
            },
        ]
    },
    {
        title: 'Backend',
        icon: <Shape34 width={64} height={64}/>,
        topics: [
            {
                topic: 'Technology consulting',
            },
            {
                topic: 'Database architecture'
            },
            {
                topic: 'Third party integrations',
            },

        ]
    },
    {
        title: 'AI & Machine Learning',
        icon: <Shape64 width={64} height={64}/>,
        topics: [
            {
                topic: 'Custom model training + deployment'
            },
            {
                topic: 'Open-source model integration'
            },
            {
                topic: 'Computer vision',
            }
        ]
    },
    {
        title: 'Product Management',
        icon: <Shape85 width={70} height={70}/>,
        topics: [
            {
                topic: 'Startup consulting'
            },
            {
                topic: 'Data driven iteration'
            },
            {
                topic: 'Key performance indicators'
            },
        ]
    },
    {
        title: 'Project Management',
        icon: <Shape5 width={64} height={64}/>,
        topics: [
            {
                topic: 'Agile workflow'
            },
            {
                topic: 'Timeline creation'
            },
            {
                topic: 'Resource allocation'
            },
            {
                topic: 'Feature prioritization'
            }
        ]
    },
    {
        title: 'Fundraising & Growth',
        icon: <Shape63 width={64} height={64}/>,
        topics: [
            {
                topic: 'Investor stories'
            },
            {
                topic: 'Pitch deck assistance'
            },
            {
                topic: 'Goal setting'
            },
        ]
    },
    {
        title: 'DevOps',
        icon: <Shape3 width={58} height={58}/>,
        topics: [
            {
                topic: 'CI/CD'
            },
            {
                topic: 'Automated testing'
            },
            {
                topic: 'Infrastructure scaling'
            },
            {
                topic: 'System monitoring'
            }
        ]
    },
    {
        title: 'Experiential',
        icon: <Shape32 width={64} height={64}/>,
        topics: [
            {
                topic: 'QR, Bluetooth, RFID, and NFC'
            },
            {
                topic: 'Geotargeting'
            },
            {
                topic: 'UWB'
            },
            {
                topic: 'Augmented Reality'
            },
        ]
    },
    {
        title: 'Blockchain',
        icon: <Shape54 width={64} height={64}/>,
        topics: [
            {
                topic: 'NFTs'
            },
            {
                topic: 'Custom contracts'
            },
            {
                topic: 'Marketplaces creation'
            },
            {
                topic: 'Exchanges'
            },
        ]
    },
]


export const WE_BUILD = ['brands', 'apps', 'websites', 'products', 'tools', 'platforms', 'plugins', 'solutions']

export const WE_KNOW = ['startups', 'business', 'strategy', 'PMF', 'workflows', 'products', 'fundraising', 'timelines']


const windowWidth = window.location.innerWidth
export const TOP_CAPABILITIES = [
    {
        title: 'Strategy',
        description: 'We meticulously plan and execute comprehensive product strategies that propel your idea forward, from defining your unique value to devising actionable specs and timelines.',
        img: <Shape40 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 300,
            y: -20
        },
    },
    {
        title: 'Branding',
        description: 'We breathe life into brands, creating captivating visual and verbal identities. We help craft a cohesive brand and story that resonates with your audience.',
        img: <Shape21 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 340,
            y: 10
        },
    },
    {
        title: 'Design',
        description: 'Our design team transforms concepts into captivating visuals, ensuring your brand communicates effectively through stunning graphics, layouts, and user experiences.',
        img: <Shape32 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 200,
            y: 10
        },
    },
    {
        title: 'UI/UX',
        description: 'We can build everything we design. That means pixel-perfect flows and interactions that captivate, engage, and convert your target audience.',
        img: <Shape5 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 240,
            y: -60
        },
    },
    {
        title: 'Development',
        description: 'We excel in building robust systems. From integrations and databases to cutting-edge machine learning solutions, we turn your ideas into functional, data-driven reality.',
        img: <Shape3 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 300,
            y: 20
        },
    },
    {
        title: 'Fundraising',
        description: 'Raising capital can be challenging. We help you navigate the world of fundraising with expertise, offering strategies, tools, and support to secure investments and drive your growth.',
        img: <Shape74 style={{width: '30vw', height: '30vw'}}/>,
        imgPosition: {
            x: 300,
            y: 60
        },
    },
];


export const MVP_STEPS = [
    {
        label: '01',
        title: 'Scope & Strategize',
        description: 'We take time during this phase to spec out your final product and future business, making every decision with your end users in mind. Our process hinges on learning your problem on an intricate level. '
    },
    {
        label: '02',
        title: 'Design',
        description: 'We bring creativity and functionality together, whether we’re starting from a clean slate or building off your existing identity. We collaborate closely to create visually compelling designs that foster unique and compelling user experiences. '
    },
    {
        label: '03',
        title: 'Develop',
        description: 'Where the magic happens. We transform the designs and specifications into a fully functional production ready solution, ensuring that your product is not only robust but scalable for future growth.'
    },
    {
        label: '04',
        title: 'Test & Feedback',
        description: "We take time to listen to your feedback, and integrate feedback from users so we can ship a tool that sets you up for success.",
    },
    {
        label: '05',
        title: 'Ship & Fundraise',
        description: 'We will ship the perfect product designed to enable you to grow. Your results are top of mind throughout every phase.'
    },
];


export const PAST_CLIENTS = [
    {
        client: 'Rea',
        tags: ['Branding', 'Design', 'Mobile App', 'Website'],
        cover: require('../img/gallery/rea/reacover.png'),
        description: 'Rewards aggregator designed to help users save'
    },
    {
        client: 'RecruitU',
        tags: ['Branding', 'Design', 'Web Platform', 'Website'],
        cover: require('../img/gallery/recruit/recruitcover.png'),
        description: 'Recruiting platform connecting students and companies'
    },
    {
        client: 'Evolve',
        tags: ['Branding', 'Design', 'Mobile App', 'Website'],
        cover: require('../img/gallery/evolveapp/evolveapp-cover.png'),
        description: 'Web3 + AR powered social platform'
    },
    {
        client: 'Starfish',
        tags: ['Design', 'Mobile App', 'Web Platform', 'Website'],
        cover: require('../img/gallery/starfishweb/starfish-web.png'),
        description: 'Social media centered around your humanitarian persona'
    },
]

export const FAQS = [
    {
        title: 'What is the Zero To One offering?',
        description: 'In our "Zero to One" offering, we join forces to transform your idea into a successful product. We specialize in guiding companies through this initial phase of business strategy, technical design, & development - we are here to help. Come with an idea, leave with a compelling brand, production ready product, and all you need to fundraise.'
    },
    {
        title: 'What is your collaborative process like?',
        description: 'We maintain constant collaboration throughout the entire journey, ensuring that the product we deliver positions you to achieve your objectives. Your success is our top priority. Typically, we hold weekly check-ins and make ourselves available for ad-hoc discussion via Slack.'
    },
    {
        title: 'Is there any flexibility with the Zero To One timeline?',
        description: "Of course! Every product is different. We typically aim to scope out a project that takes 4 months, ensuring the final deliverable holds true to our standard. We know every situation is different, and are always ready to adapt."
    },
    {
        title: 'What types of startup ventures do you typically partner with?',
        description: 'Our services cater to a diverse range of startups, spanning from technology-driven ventures to socially-conscious enterprises. We\'re committed to helping any startup with a promising idea and ambitious goals, and are confident in our ability to execute.'
    },
    {
        title: "What if I'm not starting from scratch?",
        description: "Not a problem at all! Check out our Scope Studio offering, where we'll find a way to take your existing operation to the next level, adapt to your needs and deliver impact in a meaningful way. Just reach out!"
    },
];

export const BUTTON_TEXT_ZERO_TO_ONE = 'Learn more'
export const BUTTON_TEXT_STUDIO = 'Learn more'
export const verticals = [
    {
        title: 'Zero to One',
        description: 'Bring your idea to life with an MVP build designed to help you grow & raise.',
        img: require('../img/logos/zerotoone.png'),
        buttonLabel: BUTTON_TEXT_ZERO_TO_ONE,
        route: '/zero-to-one',
        primaryRoute: "/zero-to-one"
    },
    {
        title: 'Iteration',
        description: 'Partner with Scope to take your existing operation to the next level.',
        img: require('../img/logos/studio.png'),
        buttonLabel: BUTTON_TEXT_STUDIO,
        route: "/iteration",
        primaryRoute: "/iteration"
    },
    {
        title: 'The Lab Incubator',
        description: 'Our in-house wing, where we develop advanced technologies and homegrown companies.',
        img: require('../img/logos/inhouse.png'),
        buttonLabel: 'Visit The Lab',
        route: "/lab",
        primaryRoute: "/lab",
        // new: true
    },
    {
        title: 'Scope Labs Capital',
        description: 'Our seed-stage VC firm uniquely mitigating technology risk. ',
        img: require('../img/logos/SLC-logo.png'),
        buttonLabel: 'Visit SLC',
        route: "/lab",
        primaryLink: 'https://scopelabscapital.com/',
        new: true,
        externalButton: true
    }
]


export const WHY_SCOPE = [
    {
        label: '01',
        title: "We're founders that have been there before",
        description: 'Scope is a team of founders that have experience creating numerous successful products, technologies, and brands. Our experience shapes our process and gives us an edge.'
    },
    {
        label: '02',
        title: "We're agile and move fast",
        description: "We know the plan doesn't always match reality. We excel at being adaptable, iterating fast, and always challenging our thinking. We love the ride."
    },
    {
        label: '03',
        title: "We care. Plain and simple",
        description: "We take pride in our work and ensuring our clients reach their goals. That's why we take the extra step to dive deep and get creative in any way we can."
    },
]

export const zero_to_one_highlights = [
    {
        icon: <SvgTime style={{width: 80, height: 80}}/>,
        iconMobile: <SvgTime style={{width: 32, height: 32}}/>,
        label1: 'Flexible',
        label2: 'timeframe'
    },
    {
        icon: <SvgProduct style={{width: 80, height: 80}}/>,
        iconMobile: <SvgProduct style={{width: 32, height: 32}}/>,
        label1: 'Production',
        label2: 'ready product'
    },
    {
        icon: <SvgWebsite style={{width: 80, height: 80}}/>,
        iconMobile: <SvgWebsite style={{width: 32, height: 32}}/>,
        label1: 'Custom',
        label2: 'splash page'
    },
    {
        icon: <SvgEditRow style={{width: 80, height: 80}}/>,
        iconMobile: <SvgEditRow style={{width: 32, height: 32}}/>,
        label1: 'Brand',
        label2: 'guidelines'
    },
    {
        icon: <SvgImprovement style={{width: 80, height: 80}}/>,
        iconMobile: <SvgImprovement style={{width: 32, height: 32}}/>,
        label1: 'Fundraising',
        label2: 'support'
    },
]

export const scope_studio_highlights = [
    {
        icon: <SvgTime style={{width: 80, height: 80}}/>,
        iconMobile: <SvgTime style={{width: 32, height: 32}}/>,
        label1: 'Flexible',
        label2: 'timeframe'
    },
    {
        icon: <SvgWorkflow style={{width: 80, height: 80}}/>,
        iconMobile: <SvgWorkflow style={{width: 32, height: 32}}/>,
        label1: 'Development',
        label2: 'services'
    },
    {
        icon: <SvgPaint style={{width: 80, height: 80}}/>,
        iconMobile: <SvgPaint style={{width: 32, height: 32}}/>,
        label1: 'Design',
        label2: 'services'
    },
    {
        icon: <SvgBroadcasting style={{width: 80, height: 80}}/>,
        iconMobile: <SvgBroadcasting style={{width: 32, height: 32}}/>,
        label1: 'Strategy',
        label2: 'consulting'
    },
    {
        icon: <SvgImprovement style={{width: 80, height: 80}}/>,
        iconMobile: <SvgImprovement style={{width: 32, height: 32}}/>,
        label1: 'Fundraising',
        label2: 'support'
    },
]

export const testimonials = [
    {
        person: 'Adrian Villanueva',
        title: 'Business Development Manager',
        company: 'Epic Photo Ops',
        quote: "The Scope team goes above and beyond to not only help improve our technology and resources, but they do so with the same care they would put into their own business. They are not just contractors to me, but an extended work family and valued friends."
    },
]
