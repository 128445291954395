import starfishweb from "../img/cases/starfish/starfishcover.jpg";

import epiccover from '../img/cases/epic/epiccover.jpg'

import epiccovermobile from '../img/gallery-mobile/epic/epiccover.png'

import recruitcover from '../img/cases/recruit/recruitcover.jpg'

import blnkcover from '../img/cases/evolve/evolvecover.jpg'

import reacover from '../img/cases/rea/reacover.jpg'

import stancover from '../img/cases/stan/stan-cover.png'
import glossicover from '../img/cases/glossi/glossicover.jpg'

import showopencover from '../img/cases/showopen/ShowOpen-Thumbnail-Video.jpg'

import stonkcover from '../img/cases/stonkmadness/SM-COVER.jpg'

import aigentlesscover from '../img/cases/aigentless/aigentlesscover.jpg'
export const RECRUIT_COVER = {
    src: recruitcover,
    image: {
        width: '60vw',
        aspectRatio: '1',
    },
    title: "RecruitU",
    description: "First step to final offer, all in one place",
    tags: ['Branding', 'Web App', 'Website'],
    video: require('../videos/RecruitJobBoard16x9.mp4'),
    route: '/work/recruit',
    type: ['Zero To One', 'Studio'],
    zeroToOne: true,
    studio: true
}

export const AIGENTLESS_COVER = {
    src: aigentlesscover,
    image: {
        width: '30vw',
        aspectRatio: '1',
    },
    title: "Aigentless",
    description: "The first platform for agentless leasing",
    tags: ['Branding', 'Web & Mobile App', 'Website'],
    video: require('../img/cases/aigentless/AigentlessCover.mp4'),
    // video: require('../img/cases/stonkmadness/SM_COVER.mp4'),
    route: '/work/aigentless',
    type: ['Zero To One', 'Studio'],
    zeroToOne: true,
    studio: true
}

export const SEATCLUB_COVER = {
    src: require('../img/cases/seatclub/seatclubcover.jpg'),
    image: {
        width: '30vw',
        aspectRatio: '1',
    },
    title: "SeatClub",
    description: "The first and only subscription based ticketing platform",
    tags: ['Branding', 'Mobile App', 'Website'],
    video: require('../img/cases/seatclub/SeatClubCover.mp4'),
    // video: require('../img/cases/stonkmadness/SM_COVER.mp4'),
    route: '/work/seatclub',
    type: ['Studio'],
    zeroToOne: true,
    studio: true
}

export const STONK_COVER = {
    src: stonkcover,
    image: {
        width: '60vw',
        aspectRatio: '1',
    },
    title: "Stonk Madness",
    description: "Pick the right stocks & win prizes",
    tags: ['Website', 'Web App'],
    video: require('../img/cases/stonkmadness/SM_COVER.mp4'),
    route: '/work/stonkmadness',
    type: ['Zero To One'],
    zeroToOne: true,
    studio: false
}

export const REA_COVER = {
    src: reacover,
    title: "Rea",
    image: {
        width: '36vw',
        aspectRatio: '1',
    },
    description: "Saving never felt more like earning",
    tags: ['Branding', 'Design', 'Website', 'Mobile App'],
    offset: {
        x: '24vw',
        y: '-2vw'
    },
    video: require('../videos/ReaLinkFlow.mp4'),
    route: '/work/rea',
    zeroToOne: true,
}

export const BLNK_COVER = {
    src: blnkcover,
    title: "EVOLVE",
    image: {
        width: '38vw',
        aspectRatio: '1',
    },
    description: "AR & Web3 powered social",
    tags: ['Branding', 'Design', 'Website', 'Mobile App'],
    offset: {
        x: '-23vw',
        y: '-24vw'
    },
    video: require('../videos/EvolveApp.mp4'),
    route: '/work/evolve',
    zeroToOne: true,
}

export const GLOSSI_COVER = {
    src: glossicover,
    title: "Glossi",
    image: {
        width: '55vw',
        aspectRatio: '1',
    },
    description: "Create stunning 3D renders in minutes",
    tags: ['Web Platform', 'Design'],
    offset: {
        x: '0vw',
        y: '0vw'
    },
    video: require('../videos/Glossi.mp4'),
    route: '/work/glossi',
    studio: true
}

export const EPIC_COVER = {
    src: epiccover,
    srcMobile: epiccovermobile,
    title: "Epic Photo Ops",
    image: {
        width: '30vw',
        aspectRatio: '1',
    },
    description: "Better celebrity photo ops",
    tags: ['Website', 'Line Management Tool', 'Auto Cropper'],
    offset: {
        x: '-20vw',
        y: '0vw'
    },
    video: require('../videos/EpicWebsite.mp4'),
    route: '/work/epic',
    studio: true
}

export const STARFISH_COVER = {
    src: starfishweb,
    title: "Starfish",
    image: {
        width: '35vw',
        aspectRatio: '1',
    },
    description: "Novel fundraising tech for nonprofits",
    tags: ['Website', 'Mobile App'],
    offset: {
        x: '20vw',
        y: '-20vw'
    },
    video: require('../videos/Starfish.mp4'),
    zeroToOne: true,
    studio: true
}

export const SHOW_OPEN_COVER = {
    src: showopencover,
    title: "Show Open",
    image: {
        width: '35vw',
        aspectRatio: '1',
    },
    description: "Transform your ideal event into reality",
    tags: ['Website'],
    offset: {
        x: '20vw',
        y: '-20vw'
    },
    studio: true,
    route: '/work/showopen',
    video: require('../videos/ShowOpen Shorter Thumbnail (1).mp4'),
}


export const WORK = [
    {
        case: SEATCLUB_COVER,
        offset: {
            x: '-10vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: RECRUIT_COVER,
        offset: {
            x: '10vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: STONK_COVER,
        offset: {
            x: '0vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: AIGENTLESS_COVER,
        offset: {
            x: '-20vw',
            y: '0vw'
        },
        image: {
            width: '40vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: REA_COVER,
        offset: {
            x: '24vw',
            y: '-8vw'
        },
        image: {
            width: '36vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: BLNK_COVER,
        offset: {
            x: '-23vw',
            y: '-20vw'
        },
        image: {
            width: '38vw',
            aspectRatio: '1',
        },
    },
    {
        case: GLOSSI_COVER,
        offset: {
            x: '10vw',
            y: '5vw'
        },
        image: {
            width: '55vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: SHOW_OPEN_COVER,
        offset: {
            x: '-20vw',
            y: '0vw'
        },
        image: {
            width: '30vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    // {
    //     case: SHOW_OPEN_COVER,
    //     offset: {
    //         x: '20vw',
    //         y: '-24vw'
    //     },
    //     image: {
    //         width: '35vw',
    //         aspectRatio: '1',
    //     },
    // },
]

export const WORK_HOME = [
    {
        case: SEATCLUB_COVER,
        offset: {
            x: '-10vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: RECRUIT_COVER,
        offset: {
            x: '10vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: AIGENTLESS_COVER,
        offset: {
            x: '-20vw',
            y: '0vw'
        },
        image: {
            width: '40vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: STONK_COVER,
        offset: {
            x: '24vw',
            y: '-5vw'
        },
        image: {
            width: '36vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: GLOSSI_COVER,
        offset: {
            x: '-20vw',
            y: '-20vw'
        },
        image: {
            width: '35vw',
            aspectRatio: '1',
        },
        viewable: true
    },
]

export const WORK_MVP = [
    {
        case: AIGENTLESS_COVER,
        offset: {
            x: '-20vw',
            y: '0vw'
        },
        image: {
            width: '40vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: REA_COVER,
        offset: {
            x: '24vw',
            y: '-6vw'
        },
        image: {
            width: '36vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: BLNK_COVER,
        offset: {
            x: '-23vw',
            y: '-24vw'
        },
        image: {
            width: '38vw',
            aspectRatio: '1',
        },
    },
    {
        case: RECRUIT_COVER,
        offset: {
            x: '0vw',
            y: '0vw'
        },
        image: {
            width: '70vw',
            aspectRatio: '1',
        },
        viewable: true
    },
]

export const WORK_STUDO = [
    {
        case: SEATCLUB_COVER,
        offset: {
            x: '-10vw',
            y: '0vw'
        },
        image: {
            width: '60vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: GLOSSI_COVER,
        offset: {
            x: '0vw',
            y: '0vw'
        },
        image: {
            width: '55vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: AIGENTLESS_COVER,
        offset: {
            x: '20vw',
            y: '0vw'
        },
        image: {
            width: '40vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: EPIC_COVER,
        offset: {
            x: '-22vw',
            y: '-5vw'
        },
        image: {
            width: '30vw',
            aspectRatio: '1',
        },
        viewable: true
    },
    {
        case: SHOW_OPEN_COVER,
        offset: {
            x: '20vw',
            y: '-20vw'
        },
        image: {
            width: '35vw',
            aspectRatio: '1',
        },
    },
]
