import React, {Component} from "react";
import ContactUsComponent from "../components/ContactUsComponent";
import WireframeLogo1 from "../icons/custom/wireframelogo2/WireframeLogo1";
import WireframeLogo2 from "../icons/custom/wireframelogo2/WireframeLogo2";
import WireframeLogo3 from "../icons/custom/wireframelogo2/WireframeLogo3";
import WireframeLogo4 from "../icons/custom/wireframelogo2/WireframeLogo4";
import WireframeLogo5 from "../icons/custom/wireframelogo2/WireframeLogo5";
import WireframeLogo6 from "../icons/custom/wireframelogo2/WireframeLogo6";
import WireframeLogo7 from "../icons/custom/wireframelogo2/WireframeLogo7";
import WireframeLogo8 from "../icons/custom/wireframelogo2/WireframeLogo8";
import WireframeLogo9 from "../icons/custom/wireframelogo2/WireframeLogo9";
import FeatureSection from "../components/FeatureSection";
import CapabilitiesSection from "../components/CapabilitiesSection";
import {BUTTON_TEXT_STUDIO, scope_studio_highlights} from "../utils/dummy";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import {WORK_STUDO} from "../utils/gallerydummy2";
import WorkFloatingCard from "../components/WorkFloatingCard";
import QuickLink from "../components/QuickLink";
import VericalHero from "../components/VericalHero";
import {Link} from "react-router-dom";
import AboutHighlights from "../components/AboutHighlights";
import Services from "../components/ServicesSection";
import OurProcess from "../components/OurProcess";


class Studio extends Component {
    state = {
        scrollPosition: window.pageYOffset,
        darkBody: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        this.setState({
            scrollPosition: currentScrollPos,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    switchGraphic = (mobile) => {
        const size = mobile ? '50vw' : '35vw'
        const breakpoints = this.props.mobile ? [0, 50, 100, 150, 200, 250, 300, 350] : [200, 400, 600, 800, 1000, 1200, 1400, 1600];
        const {scrollPosition} = this.state;
        if (this.state.scrollPosition < breakpoints[0]) {
            return <WireframeLogo1 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[0] && this.state.scrollPosition < breakpoints[1]) {
            return <WireframeLogo2 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[1] && this.state.scrollPosition < breakpoints[2]) {
            return <WireframeLogo3 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[2] && this.state.scrollPosition < breakpoints[3]) {
            return <WireframeLogo4 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[3] && this.state.scrollPosition < breakpoints[4]) {
            return <WireframeLogo5 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[4] && this.state.scrollPosition < breakpoints[5]) {
            return <WireframeLogo6 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[5] && this.state.scrollPosition < breakpoints[6]) {
            return <WireframeLogo7 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[6] && this.state.scrollPosition < breakpoints[7]) {
            return <WireframeLogo8 width={size} height={size}/>
        } else {
            return <WireframeLogo9 width={size} height={size}/>
        }
    }

    toggleDarkNavbar = (toggle) => {
        if (toggle) {
            document.body.style.backgroundColor = 'black';
            this.props.toggleDarkNavbar(true)
            this.setState({darkBody: true})
        } else {
            document.body.style.backgroundColor = 'white';
            this.props.toggleDarkNavbar(false)
            this.setState({darkBody: false})
        }
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <VericalHero
                    width={'75%'}
                    header={'Take your existing operation to the next level'}
                    label={'Scope Iteration'}
                    name={'Scope Studio'}
                    highlights={scope_studio_highlights}
                    logo={require('../img/logos/studio.png')}
                    bgImg={require('../img/Holographic 3D shape 18 (1).png')}
                    description={'We work with forward-thinking companies seeking to transform their products, gain traction, and raise funds.'}
                    mobile={this.props.mobile}/>
                <div className='page-h-padding'>
                    <div style={{padding: this.props.mobile ? '5vh 0px 0 0px' : '15vh 0 0 0vh', gap: 80}}
                         className={this.props.mobile ? 'col-ac' : 'col-plain'}>
                        <FadeInOnScroll delay={800} triggerImmediately>
                            <div className='row-plain' style={{width: this.props.mobile && '100%'}}>
                                                <span className='body1-bold base-black-50'>
                                                    01
                                                </span>
                                <span className='title1 mb-0'>
                                                  We collaborate closely to understand your vision, define product goals, and identify potential tech and market challenges. This sets a clear roadmap for development.
                                                </span>
                            </div>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={1000} triggerImmediately
                                        style={{
                                            paddingTop: !this.props.mobile && '2vh',
                                            width: this.props.mobile && '100%',
                                            marginLeft: !this.props.mobile && 40
                                        }}>
                            <div className='row-plain'>
                                                <span className='body1-bold base-black-50'>
                                                    02
                                                </span>
                                <span className='title1 mb-0'>
                                                 We'll manage the roadmap iteratively with your goals and business in mind. Shifting proiorities as new challenges arise.
                                                </span>
                            </div>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={1200} triggerImmediately
                                        style={{
                                            paddingTop: !this.props.mobile && '2vh',
                                            width: this.props.mobile && '100%',
                                            marginLeft: !this.props.mobile && 40 * 2
                                        }}>
                            <div className='row-plain' style={{}}>
                                                <span className='body1-bold base-black-50'>
                                                    03
                                                </span>
                                <span className='title1 mb-0'>
We approach every project strategically, focusing not only on technical aspects but also on your broader business goals and fundraising success.
                                                </span>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
                {/*<div className=''>*/}
                {/*    <AboutHighlights darkBody={this.state.darkBody} mobile={this.props.mobile}/>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Services*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<OurProcess/>*/}
                <div>
                    <CapabilitiesSection
                        darkBody={this.state.darkBody}
                        tablet={this.props.tablet}
                        mobile={this.props.mobile}
                    />
                </div>
                <FeatureSection
                    darkBody={this.state.darkBody}
                    tablet={this.props.tablet}
                    mobile={this.props.mobile}
                />
                <div style={{paddingTop: '0vh'}}>
                    <div ref={this.sectionRef} id="experience-section" className={'col-ac page-h-padding'}
                         style={{paddingTop: '10vh', paddingBottom: '10vh'}}>
                        {WORK_STUDO.map((item, i) =>
                            <WorkFloatingCard item={item} i={i} mobile={this.props.mobile}
                                              darkBody={this.state.darkBody}/>
                        )}
                        <QuickLink
                            title={'Explore our work'}
                            sm
                            toRight
                            onClick={() => this.props.history.push('/work')}
                            mobile={this.props.mobile}
                        />
                    </div>
                </div>
                {/*<div style={{paddingTop:'20vh'}}>*/}
                {/*    <TestimonialsSection*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className=''>
                    <ContactUsComponent mobile={this.props.mobile} darkBody={this.state.darkBody}/>
                </div>
                <Link to={'/contact'} style={{position: "fixed", bottom: 20, right: 20}}>
                    <FadeInOnScroll triggerImmediately delay={900}>
                        <div className='primary-button body1-bold'>
                            <img src={require('../img/logos/studio.png')} className='inverseFilter mr-12'
                                 style={{height: 20}}/>
                            {BUTTON_TEXT_STUDIO}
                        </div>
                    </FadeInOnScroll>
                </Link>
            </div>
        )
    }
}

export default Studio
