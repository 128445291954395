import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import MousePositionWrapper from "./MousePositionWrapper";
import SvgArrowRight from "../icons/SvgArrowRight";


class AboutHighlightTitle extends Component {
    state = {
        mouseX: null,
        mouseY: null,
        hovering: false,
    }

    handleMouseMove = (mouseX, mouseY) => {
        this.setState({
            mouseX, mouseY
        })
    }

    onMouseEnter = () => {
        this.setState({hovering: true})
        this.props.onMouseEnter()
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    render() {
        return (
            <FadeInOnScroll triggerImmediately={this.props.i === 0} delay={(this.props.i + 1) * 150}>
                <MousePositionWrapper
                    handleMouseMove={this.handleMouseMove}
                >
                    <div onMouseEnter={this.onMouseEnter}
                         onMouseLeave={this.onMouseLeave}
                         className='about-highlight-title title1 transition1 pv-8 row-ac-jb relative'
                         style={{
                             overflow: "hidden",
                             // backgroundColor: this.props.highlighted === this.props.i ? '#FFFFFF14' : '#ffffff00',
                             color: this.props.highlighted === this.props.i ? '#000000' : '#0000004d',
                             // transform: `scale(${this.state.hovering ? 1.02 : 1})`,
                         }}
                    >
                        {/*<div className='absolute cover-parent hero-cover-2'*/}
                        {/*     style={{*/}
                        {/*         opacity: (this.props.highlighted === this.props.i) ? .3 : 0,*/}
                        {/*         transition: "300ms ease-in-out",*/}
                        {/*         zIndex: -1*/}
                        {/*     }}/>*/}
                        <div className={this.props.darkBody ? 'base-white-100' : 'base-black-100'}
                             >
                            {this.props.highlight.title}
                        </div>
                        {this.props.mobile ?
                            <SvgArrowRight
                                width={this.props.mobile ? 40 : 64}
                                height={this.props.mobile ? 40 : 64}
                                className={this.props.mobile ? 'ph-12 pv-4' : this.props.tablet ? 'p-12' : 'p-20'}
                                style={{
                                    opacity: this.state.hovering ? 1 : 0,
                                    transform: `rotate(90deg) skewY(${this.state.hovering ? `${(this.props.i - 2) * 3}deg` : '0'})`,
                                    transition: '300ms ease-in-out '
                                }}
                            />
                            :
                            <SvgArrowRight
                                width={this.props.mobile ? 40 : 64}
                                height={this.props.mobile ? 40 : 64}
                                className={this.props.mobile ? 'ph-12 pv-4' : this.props.tablet ? 'p-12' : 'p-20'}
                                style={{
                                    opacity: this.state.hovering ? 1 : 0,
                                    transform: `skewY(${this.state.hovering ? `${(this.props.i - 2) * -3}deg` : '0'})`,
                                    transition: '300ms ease-in-out '
                                }}
                            />
                        }
                    </div>
                    {!this.props.mobile ?
                        <>
                            <div className='absolute transition1' style={{
                                top: this.state.mouseY,
                                left: this.state.mouseX - 12,
                                width: 12, height: 12,
                                backgroundColor: '#000000',
                                opacity: this.state.hovering ? .5 : 0,
                            }}/>
                            <div className='absolute transition1' style={{
                                top: this.state.mouseY / 2,
                                left: (this.state.mouseX - 12) / 4,
                                width: 8, height: 8,
                                backgroundColor: '#000000CD',
                                opacity: this.state.hovering ? .5 : 0,
                            }}/>
                            <div className='absolute transition1' style={{
                                bottom: this.state.mouseY / 2,
                                right: (this.state.mouseX - 12) / 4,
                                width: 8, height: 8,
                                backgroundColor: '#000000CD',
                                opacity: this.state.hovering ? .5 : 0,
                            }}/>
                            <div className='absolute transition1' style={{
                                top: this.state.mouseY / 2,
                                right: (this.state.mouseX - 12) / 4,
                                width: 8, height: 8,
                                backgroundColor: '#000000CD',
                                opacity: this.state.hovering ? .5 : 0,
                            }}/>
                        </>
                        :
                        <></>
                    }
                </MousePositionWrapper>
            </FadeInOnScroll>
        )
    }
}

export default AboutHighlightTitle
