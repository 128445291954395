import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import AboutHighlightTitle from "./AboutHighlightTitle";
import {TypeAnimation} from "react-type-animation";
import Shape21 from "../icons/pack1/Shape21";
import Shape94 from "../icons/pack1/Shape94";
import Shape29 from "../icons/pack1/Shape29";
import Shape49 from "../icons/pack1/Shape49";


const HIGHLIGHTS = [
    {
        title: 'Weekly Sprints',
    },
    {
        title: 'Data Driven Goals',
    },
    {
        title: 'Product and Business',
    },
    {
        title: 'Relationship Oriented',
    },
]

class AboutHighlights extends Component {
    state = {
        highlighed: 0
    }

    render() {
        return (
            <>
                <section className={this.props.mobile ? 'col-plain' : 'row-jb page-h-padding'}
                         style={{
                             paddingTop: '15vh'
                         }}>
                    <div className='' style={{flex: .8}}>
                        <div>
                            {HIGHLIGHTS.map((highlight, i) =>
                                <AboutHighlightTitle
                                    i={i}
                                    highlight={highlight}
                                    key={highlight.title}
                                    highlighted={this.state.highlighed}
                                    darkBody={this.props.darkBody}
                                    onMouseEnter={() => this.setState({highlighed: i})}
                                    mobile={this.props.mobile}
                                />
                            )}
                        </div>
                    </div>
                    <div className='relative col-je'
                         style={{
                             zIndex: 0,
                             marginTop: -80,
                             width:'35vw'
                         }}>
                        <div
                            className={`title1 transition1 col-plain ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                            style={{
                                opacity: this.state.highlighed === 0 ? 1 : 0,
                                transform: `translateY(${this.state.highlighed === 0 ? '0px' : '60px'})`,
                                top: 0,
                                right: 0,
                                backdropFilter: this.props.mobile && 'none',
                                backgroundColor: this.props.mobile && 'transparent',
                                padding: this.props.mobile && 20,
                            }}
                        >
                            <Shape21 width={140} height={140}/>
                            <div>
                                With a proven <span className='highlight'>track record</span> of success and an
                                unparalleled
                                drive to deliver, our work is
                                unmatched. Our founders, leading <span className='highlight'>minds from MIT</span>, have
                                been pioneering for over a decade to solve some of the most complex challenges within
                                the
                                industry.
                                <TypeAnimation
                                    sequence={[
                                        '',
                                        1000,
                                    ]}
                                />
                            </div>
                        </div>
                        <div
                            className={`about-block__content title1 transition1 col-plain ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                            style={{
                                opacity: this.state.highlighed === 1 ? 1 : 0,
                                transform: `translateY(${this.state.highlighed === 1 ? '0px' : '60px'})`,
                                top: 0,
                                right: 0,
                                backdropFilter: this.props.mobile && 'none',
                                backgroundColor: this.props.mobile && 'transparent',
                                padding: this.props.mobile && 20
                            }}
                        >
                            <Shape94 width={140} height={140}/>
                            <div>
                                We take a strategic and efficient approach to all our work. Simply completing a project
                                is
                                not
                                enough for us—everything we do must be <span className='highlight'>high quality</span>,
                                delivered on schedule, utilize the
                                latest
                                technologies, and adhere to the <span className='highlight'>highest standards.</span>
                                <TypeAnimation
                                    sequence={[
                                        '',
                                        1000,
                                    ]}
                                />
                            </div>
                        </div>
                        <div
                            className={`about-block__content title1 transition1 col-plain ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                            style={{
                                opacity: this.state.highlighed === 2 ? 1 : 0,
                                transform: `translateY(${this.state.highlighed === 2 ? '0px' : '60px'})`,
                                top: 0,
                                right: 0,
                                backdropFilter: this.props.mobile && 'none',
                                backgroundColor: this.props.mobile && 'transparent',
                                padding: this.props.mobile && 20,
                                position: this.props.mobile && 'relative',
                            }}
                        >
                            <Shape29 width={140} height={140}/>
                            <div>
                                As professionals with experience across the full development lifecycle, we understand
                                your
                                needs
                                thoroughly. While our core expertise lies in <span
                                className='highlight'>digital development</span>,
                                we have incredible skills
                                in
                                design, project management, strategy, and more. Most importantly, we’ve walked a mile in
                                your
                                shoes and are here to <span className='highlight'>problem solve</span> with you.
                                <TypeAnimation
                                    sequence={[
                                        '',
                                        1000,
                                    ]}
                                />
                            </div>
                        </div>
                        <div
                            className={`about-block__content title1 transition1 col-plain ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                            style={{
                                opacity: this.state.highlighed === 3 ? 1 : 0,
                                transform: `translateY(${this.state.highlighed === 3 ? '0px' : '60px'})`,
                                top: 0,
                                right: 0,
                                backdropFilter: this.props.mobile && 'none',
                                backgroundColor: this.props.mobile && 'transparent',
                                padding: this.props.mobile && 20
                            }}
                        >
                            <Shape49 width={140} height={140}/>
                            <div>
                                We invest in our clients success and <span
                                className='highlight'>view their wins as our own</span>. Through transparent
                                , mutual accountability, and shared impact, we align efforts and <span
                                className='highlight'>optimize outcomes.</span>
                                <TypeAnimation
                                    sequence={[
                                        '',
                                        1000,
                                    ]}
                                />
                            </div>
                        </div>
                        {/*<div className={this.props.darkBody ? 'highlight-progress-dark' : 'highlight-progress'}>*/}
                        {/*    {HIGHLIGHTS.map((highlight, i) =>*/}
                        {/*        <div key={highlight.title} style={{opacity: this.state.highlighed === i ? 1 : .2}}/>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </section>
            </>
        )
    }
}

export default AboutHighlights
