import React, {Component} from 'react';
import HeroSection from "../components/HeroSection";
import ContactUsComponent from "../components/ContactUsComponent";
import {wait} from "../utils/timestamp";
import TopCapabilitiesSection from "../components/TopCapabilitiesSection";
import {WORK, WORK_HOME} from "../utils/gallerydummy2";
import WorkFloatingCard from "../components/WorkFloatingCard";
import QuickLink from "../components/QuickLink";
import {Link, withRouter} from "react-router-dom";
import Shape17 from "../icons/pack1/Shape17";
import TheLabWidget from "../components/TheLabWidget";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            darkBody: false,
            autoScrolling: false,
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    scrollToSection = (id) => {
        const element = document.getElementById(id);
        this.setState({autoScrolling: true})
        wait(1000).then(() => {
            this.setState({autoScrolling: false})
        })
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    render() {
        return (
            <div className='relative'
                 style={{pointerEvents: this.state.autoScrolling && 'none', width: '100vw'}}>
                <div className='relative'>
                    <div className='absolute cover-parent hero-cover-2'
                         style={{opacity: this.state.darkBody ? 0 : .4, transition: "300ms ease-in-out"}}/>
                    <div className='hero-blur-layer'/>
                    <HeroSection scrollToSection={this.scrollToSection}
                                 loading={this.props.loading}
                                 tablet={this.props.tablet}
                                 mobile={this.props.mobile}
                    />
                </div>
                <div className='' id={'what-we-do-section'}>
                    <div style={{padding: '10vh 0'}}>
                        <TopCapabilitiesSection
                            darkBody={this.state.darkBody}
                            tablet={this.props.tablet}
                            mobile={this.props.mobile}
                        />
                    </div>
                    <div className='relative page-h-padding row-je' style={{marginTop: this.props.mobile ? -20 :-80}}>
                        <Link to={'/work'} className=' base-black-100 row-ac'
                              style={{marginRight: '6vw'}}>
                            <Shape17 width={this.props.mobile ? 80 : 200} height={this.props.mobile ? 80 : 200}
                                     style={{transform: 'rotate(120deg) scaleY(-1)', marginRight:this.props.mobile ? -20 : -60}}/>
                            <p className='title1 uppercase mt-0' style={{marginBottom: -6}}>
                                &nbsp;&nbsp;&nbsp;SOME<br/>OF OUR<br/>WORK
                            </p>
                        </Link>
                    </div>
                    <div ref={this.sectionRef} id="experience-section" className={'col-ac page-h-padding'}
                         style={{paddingTop:this.props.mobile ? '5vh' : '0vh', paddingBottom: '10vh'}}>
                        {WORK_HOME.map((item, i) =>
                            <WorkFloatingCard key={item.case.title} item={item} i={i} mobile={this.props.mobile}
                                              darkBody={this.state.darkBody}/>
                        )}
                        <QuickLink
                            title={'Explore our work'}
                            sm
                            toRight
                            onClick={() => this.props.history.push('/work')}
                            mobile={this.props.mobile}
                        />
                    </div>
                </div>
                {/*<div style={{paddingTop: '30vh'}}>*/}
                {/*    <TheLabWidget*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                <ContactUsComponent
                    darkBody={this.state.darkBody}
                    mobile={this.props.mobile}
                />
            </div>
        );
    }
}

export default withRouter(Home);
