import React, {Component} from "react";
import {Link} from "react-router-dom";
import SvgArrowRight from "../icons/SvgArrowRight";

class NavbarItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
    }

    handleHover = () => {
        this.setState({
            isHovered: !this.state.isHovered
        });
    };

    render() {
        if (this.props.external) {
            return (
                <a
                    href={this.props.external}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={` ${this.props.darkBody ? 'nav-item-dark' : 'nav-item'} body2-bold ${this.props.darkBody ? 'base-white-50' : 'base-black-50'}`}
                    style={{textDecoration: 'none', marginRight: this.props.last && 0, whiteSpace: "nowrap",}}>
                        <p className='mb-8'
                           style={{color: this.props.active && (this.props.darkBody ? 'white' : 'black')}}>{this.props.label}&nbsp;
                            <SvgArrowRight width={14} height={14} style={{transform:'rotate(-45deg) scale(1.3)', marginBottom:-1}}/>
                        </p>
                    <div className='mb-4' style={{
                        width: this.props.active && '100%',
                        opacity: this.props.active && 1,
                        backgroundColor: (this.props.darkBody ? 'white' : 'black')
                    }}/>
                </a>
            )
        }
        if (!this.props.route) {
            return (
                <div
                    className={` ${this.props.darkBody ? 'nav-item-dark' : 'nav-item'} body2-bold ${this.props.darkBody ? 'base-white-50' : 'base-black-50'}`}
                    style={{textDecoration: 'none', marginRight: this.props.last && 0, whiteSpace: "nowrap",}}>
                    <p className='mb-8'
                       style={{color: this.props.active && (this.props.darkBody ? 'white' : 'black')}}>{this.props.label}</p>
                    <div className='mb-4' style={{
                        width: this.props.active && '100%',
                        opacity: this.props.active && 1,
                        backgroundColor: (this.props.darkBody ? 'white' : 'black')
                    }}/>
                </div>
            );
        }
        return (
            <Link
                to={this.props.route}
                className={` ${this.props.darkBody ? 'nav-item-dark' : 'nav-item'} body2-bold ${this.props.darkBody ? 'base-white-50' : 'base-black-50'}`}
                style={{textDecoration: 'none', marginRight: this.props.last && 0, whiteSpace: "nowrap",}}>
                <p className='mb-8'
                   style={{color: this.props.active && (this.props.darkBody ? 'white' : 'black')}}>{this.props.label}</p>
                <div className='mb-4' style={{
                    width: this.props.active && '100%',
                    opacity: this.props.active && 1,
                    backgroundColor: (this.props.darkBody ? 'white' : 'black')
                }}/>
            </Link>
        );
    }
}

export default NavbarItem;
