import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const DropdownMenu = ({ darkBody, children }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const showDropdown = () => setIsDropdownVisible(true);
    const hideDropdown = () => setIsDropdownVisible(false);

    const handleScroll = () => {
        if (window.scrollY > 50) hideDropdown();
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div
            className='nav-dropdown-wrapper'
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
        >
            {children}
            {isDropdownVisible && (
                <ul
                    className='nav-dropdown'
                    style={{
                        display: isDropdownVisible ? 'block' : 'none',
                        borderColor: darkBody ? 'white' : '#00000020',
                        backgroundColor: darkBody ? 'black' : '#ffffff50'
                    }}
                >
                    <Link to='/zero-to-one' onClick={hideDropdown}>
                        <li className={`${darkBody ? 'nav-dd-item-dark' : 'nav-dd-item'}`}>
                            <p className='body1-bold m-0'>Zero to One</p>
                            <p className='m-0 body3' style={{ opacity: 0.6 }}>
                                Bring your idea to life with an MVP build designed to help you grow & raise.
                            </p>
                        </li>
                    </Link>
                    <Link to='/iteration' onClick={hideDropdown}>
                        <li className={`${darkBody ? 'nav-dd-item-dark' : 'nav-dd-item'}`}>
                            <p className='body1-bold m-0'>Iteration</p>
                            <p className='m-0 body3' style={{ opacity: 0.6 }}>
                                Partner with Scope to take your existing operation to the next level.
                            </p>
                        </li>
                    </Link>
                </ul>
            )}
        </div>
    );
};

DropdownMenu.propTypes = {
    darkBody: PropTypes.bool
};

export default DropdownMenu;
