import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
        <path
            d="M66.136 30.385q4.4-.455 8.823-1.145a33.401 33.401 0 0 0-25.568-11.88c6.815 0 12.874 5.106 16.745 13.025ZM49.391 17.36a33.397 33.397 0 0 0-25.567 11.88q4.421.69 8.822 1.145c3.872-7.92 9.93-13.025 16.745-13.025ZM28.49 56.071l.178 1.613a787.07 787.07 0 0 0-11.961.446 33.447 33.447 0 0 1 0-14.538c3.982.177 7.978.31 11.96.428a58.413 58.413 0 0 0-.31 3.273 32.4 32.4 0 0 0-.09 1.671c-.028.446-.028.905-.028 1.348a2.913 2.913 0 0 0-.015.355c-.282.061-.562.133-.844.194.282.058.562.133.844.19a2.916 2.916 0 0 0 .015.356c0 .49.014.978.028 1.466a.924.924 0 0 0 .016.209c.015.442.044.887.074 1.332.045.547.074 1.11.133 1.657Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M32.646 30.385q-4.402-.455-8.822-1.145a33.369 33.369 0 0 0-7.116 14.347q5.98.268 11.961.436a44.258 44.258 0 0 1 3.977-13.638ZM82.878 50.861a33.593 33.593 0 0 1-.8 7.269q-5.995-.27-11.96-.446c.059-.531.118-1.066.177-1.613.03-.28.06-.546.074-.83.015-.28.045-.546.06-.827.028-.445.059-.89.073-1.332a1.017 1.017 0 0 0 .014-.209c.016-.488.03-.977.03-1.466a2.916 2.916 0 0 0 .015-.355c.266-.058.548-.133.83-.191-.282-.06-.564-.133-.83-.194a2.913 2.913 0 0 0-.014-.355c0-.443 0-.902-.03-1.348-.015-.56-.044-1.11-.089-1.67a58.404 58.404 0 0 0-.31-3.274c3.98-.118 7.964-.251 11.96-.428a33.593 33.593 0 0 1 .8 7.27Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M70.113 44.023q5.979-.173 11.961-.437A33.367 33.367 0 0 0 74.96 29.24q-4.424.69-8.823 1.145a44.303 44.303 0 0 1 3.977 13.638ZM49.391 84.354A33.394 33.394 0 0 0 74.96 72.476q-4.424-.69-8.823-1.145c-3.871 7.92-9.93 13.023-16.745 13.023ZM28.67 57.69q-5.981.174-11.962.44a33.354 33.354 0 0 0 7.114 14.343q4.424-.69 8.823-1.142a44.34 44.34 0 0 1-3.976-13.64Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M70.113 57.69a44.304 44.304 0 0 1-3.977 13.641q4.402.451 8.823 1.145a33.383 33.383 0 0 0 7.115-14.346q-5.982-.27-11.96-.44ZM32.645 71.331q-4.4.451-8.823 1.142a33.392 33.392 0 0 0 25.57 11.88c-6.816 0-12.876-5.102-16.747-13.022Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M56.53 70.629q4.796.208 9.606.702a44.304 44.304 0 0 0 3.977-13.64q-6.024-.17-12.05-.24a97.41 97.41 0 0 1-1.533 13.178ZM28.67 57.69a44.34 44.34 0 0 0 3.975 13.641q4.814-.494 9.608-.702a97.408 97.408 0 0 1-1.534-13.179q-6.024.074-12.05.24ZM70.113 44.023a44.303 44.303 0 0 0-3.977-13.638q-4.812.494-9.606.702a97.318 97.318 0 0 1 1.534 13.173q6.024-.07 12.05-.237ZM42.253 31.087q-4.797-.212-9.607-.702a44.258 44.258 0 0 0-3.977 13.638q6.024.17 12.05.237a97.317 97.317 0 0 1 1.534-13.173Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M40.717 44.257c-.029.654-.073 1.304-.073 1.97-2.873 1.896-6.78 3.14-12.42 4.44a2.913 2.913 0 0 1 .015-.355c0-.443 0-.902.028-1.348a32.4 32.4 0 0 1 .09-1.67c.074-1.111.178-2.193.31-3.274 4.013.119 8.039.194 12.05.237ZM40.717 57.447c-4.011.061-8.037.133-12.05.237-.058-.531-.118-1.066-.177-1.613-.06-.546-.088-1.11-.133-1.657-.03-.445-.06-.89-.074-1.332a.924.924 0 0 1-.016-.209 49.383 49.383 0 0 1-.028-1.466 2.916 2.916 0 0 1-.015-.355c5.64 1.304 9.547 2.547 12.42 4.44.014.654.044 1.305.073 1.955ZM70.561 50.667c-5.64-1.3-9.563-2.544-12.42-4.44a96.327 96.327 0 0 0-.073-1.97q6.018-.065 12.05-.237c.132 1.082.237 2.163.31 3.273.045.561.074 1.11.088 1.671.03.446.03.905.03 1.348a2.913 2.913 0 0 1 .015.355ZM70.561 51.052a2.916 2.916 0 0 1-.014.355c0 .49-.015.978-.03 1.466a1.017 1.017 0 0 1-.015.209c-.014.442-.045.887-.074 1.332-.014.28-.044.547-.059.827-.014.284-.045.55-.074.83-.059.547-.118 1.082-.177 1.613q-6.04-.156-12.05-.237c.029-.65.058-1.3.074-1.954 2.856-1.894 6.78-3.137 12.42-4.441ZM32.646 30.385q4.812.494 9.607.702c1.609-8.32 4.205-13.727 7.138-13.727-6.815 0-12.873 5.106-16.745 13.025ZM42.253 70.629q-4.797.208-9.608.702c3.871 7.92 9.931 13.023 16.746 13.023-2.933 0-5.53-5.407-7.138-13.725ZM66.136 71.331q-4.812-.494-9.606-.702c-1.61 8.318-4.207 13.725-7.139 13.725 6.815 0 12.874-5.103 16.745-13.023Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M56.53 31.087q4.796-.212 9.606-.702c-3.871-7.92-9.93-13.025-16.745-13.025 2.932 0 5.53 5.406 7.139 13.727ZM56.528 70.623c-.149.769-.31 1.524-.474 2.25q-.288 1.266-.621 2.4c-.09.337-.193.679-.297 1.005-.237.77-.474 1.48-.726 2.131a8.226 8.226 0 0 1-.296.74c-1.184 2.843-2.546 4.664-4.011 5.092a1.537 1.537 0 0 1-.444.104 2.446 2.446 0 0 1-.532 0 1.558 1.558 0 0 1-.446-.104 3.198 3.198 0 0 1-1.184-.65 7.256 7.256 0 0 1-.873-.903 11.526 11.526 0 0 1-1.065-1.645c-.119-.205-.223-.428-.341-.665a13.909 13.909 0 0 1-.43-.93c-.147-.342-.28-.683-.413-1.039a31.752 31.752 0 0 1-.844-2.472 37.27 37.27 0 0 1-.622-2.339 66.123 66.123 0 0 1-.652-2.975c1.096-.043 2.192-.09 3.286-.104.8-.03 1.615-.03 2.414-.03.295-.013.591-.013.888-.013.178.754.355 1.552.548 2.382.192-.83.37-1.628.548-2.382.296 0 .576 0 .873.014.8 0 1.599.014 2.413.029 1.095.014 2.206.06 3.301.104Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M71.391 50.861c-.282.058-.564.133-.83.19-5.64 1.305-9.563 2.548-12.42 4.442a13.938 13.938 0 0 0-2.383 1.94c-2.71 2.77-4.263 6.737-5.817 13.043-.178.754-.356 1.552-.548 2.382-.193-.83-.37-1.628-.548-2.382-1.554-6.306-3.108-10.273-5.817-13.043a13.335 13.335 0 0 0-2.384-1.94c-2.873-1.894-6.78-3.137-12.42-4.441-.282-.058-.562-.133-.844-.19.282-.062.562-.134.844-.195 5.64-1.3 9.547-2.544 12.42-4.44a14.711 14.711 0 0 0 2.384-1.938c2.709-2.77 4.263-6.737 5.802-13.042.193-.77.37-1.57.563-2.4.192.83.37 1.63.562 2.4 1.54 6.305 3.094 10.272 5.803 13.042a14.648 14.648 0 0 0 2.384 1.937c2.856 1.897 6.78 3.14 12.42 4.441.265.061.547.133.83.194Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M43.028 57.433c-.771 0-1.54 0-2.31.014-.03-.65-.06-1.3-.074-1.954a13.335 13.335 0 0 1 2.384 1.94ZM43.028 44.29a14.711 14.711 0 0 1-2.384 1.936c0-.665.044-1.315.073-1.969.771.015 1.54.015 2.31.032ZM58.142 55.493a97.293 97.293 0 0 1-.074 1.954c-.77-.014-1.54-.014-2.31-.014a13.938 13.938 0 0 1 2.384-1.94ZM58.142 46.226a14.648 14.648 0 0 1-2.384-1.937c.77-.017 1.54-.017 2.31-.032.029.654.058 1.304.074 1.97ZM56.528 31.082c-2.191.09-4.397.15-6.573.165-.192-.77-.37-1.57-.562-2.4-.193.83-.37 1.63-.563 2.4a190.704 190.704 0 0 1-6.573-.165c.208-1.035.415-2.027.652-2.975q.154-.707.356-1.377c.22-.812.458-1.599.71-2.324q.199-.577.4-1.11c.133-.356.266-.695.413-1.021.133-.341.282-.65.43-.949.118-.237.237-.456.34-.665.193-.34.371-.65.563-.934.119-.176.252-.352.37-.517a.933.933 0 0 1 .133-.176 1.831 1.831 0 0 1 .266-.298.925.925 0 0 1 .104-.119l.088-.087a2.421 2.421 0 0 1 .342-.326.206.206 0 0 1 .073-.075.75.75 0 0 1 .134-.087 2.07 2.07 0 0 1 .208-.165c.073-.043.133-.087.206-.133a3.944 3.944 0 0 1 .548-.252 2.358 2.358 0 0 1 .534-.118 2.447 2.447 0 0 1 .532 0 2.358 2.358 0 0 1 .533.118 3.944 3.944 0 0 1 .548.252c.074.046.133.09.207.133a2.075 2.075 0 0 1 .206.165c.045.029.076.058.119.087a1.731 1.731 0 0 1 .237.208l.074.075a2.031 2.031 0 0 1 .178.176 7.513 7.513 0 0 1 .533.622c.119.165.252.341.37.517a15.15 15.15 0 0 1 .785 1.38c.147.295.295.607.444.945.104.237.207.474.31.726.074.162.134.341.207.517.133.356.268.726.401 1.11.103.327.221.668.325 1.024.104.324.207.665.297 1.006.059.191.118.4.161.607.105.339.194.694.282 1.065.06.237.119.474.178.725.164.726.325 1.48.474 2.25Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M48.83 31.247c-1.54 6.305-3.093 10.272-5.802 13.042-.771-.017-1.54-.017-2.31-.032a95.06 95.06 0 0 1 1.539-13.175c2.176.09 4.382.15 6.573.165ZM58.068 44.257c-.77.015-1.54.015-2.31.032-2.71-2.77-4.263-6.737-5.803-13.042 2.176-.015 4.382-.076 6.573-.165a99.912 99.912 0 0 1 1.54 13.175ZM48.845 70.476c-.297 0-.593 0-.888.014-.8 0-1.615 0-2.414.029-1.094.014-2.19.06-3.286.104a94.953 94.953 0 0 1-1.54-13.176c.771-.014 1.54-.014 2.31-.014 2.71 2.77 4.264 6.737 5.818 13.043ZM58.068 57.447a99.795 99.795 0 0 1-1.54 13.176c-1.095-.043-2.206-.09-3.301-.104-.814-.015-1.614-.03-2.413-.03-.297-.013-.577-.013-.873-.013 1.554-6.306 3.108-10.273 5.817-13.043.77 0 1.54 0 2.31.014Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
    </svg>
)

export default SvgComponent
