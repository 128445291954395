import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import {withRouter} from "react-router-dom";
import TiltEffect from "./wrappers/TiltEffect";
import SvgChevronForward from "../icons/SvgChevronForward";
import SvgArrowRight from "../icons/SvgArrowRight";
import SvgTrendingForward from "../icons/SvgTrendingForward";

class WorkFloatingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            mouseX: 0,
            mouseY: 0,
            inViewport: false,
            isParentHovered: false,
            isPlaying: false,
        };
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        // Initialize the Intersection Observer
        const observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0.2, // Adjust this threshold as needed
        });
        observer.observe(this.wrapperRef);
    }

    // Intersection Observer callback
    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            this.setState({inViewport: entry.isIntersecting});
            if (entry.isIntersecting) {
                this.startVideo()
            } else {
                this.stopVideo()
            }
        });
    };

    handleMouseEnter = (e) => {
        this.setState({
            isHovered: true,
        });
    };

    handleMouseLeave = () => {
        this.setState({
            isHovered: false,
        });
    };

    handleMouseEnterParent = (e) => {
        this.setState({
            isParentHovered: true,
        });
    };

    handleMouseLeaveParent = () => {
        this.setState({
            isParentHovered: false,
        });
    };

    // Method to start the video
    startVideo = () => {
        if (this.videoRef.current) {
            this.videoRef.current.play();
            this.setState({isPlaying: true});
        }
    }

    // Method to stop the video
    stopVideo = () => {
        if (this.videoRef.current) {
            this.videoRef.current.pause();
            this.setState({isPlaying: false});
        }
    }

    handleMouseMove = (e) => {
        const boundingBox = this.wrapperRef.getBoundingClientRect();
        const mouseX = e.clientX - boundingBox.left;
        const mouseY = e.clientY - boundingBox.top;

        this.setState({
            mouseX,
            mouseY,
        });
    };

    render() {
        const {item, i} = this.props;
        const {isHovered, mouseX, mouseY, isParentHovered} = this.state;

        const buttonStyle = {
            position: "absolute",
            transition: "left 0.2s, top 0.2s",
            left: mouseX - 80, // Adjust the positioning as needed
            top: mouseY - 20, // Adjust the positioning as needed
            display: isHovered ? "block" : "none",
        };

        return (
            <div
                onClick={() => item.viewable && this.props.history.push(item.case.route)}
                className="work-item"
                style={{
                    transform: !this.props.mobile && `translateX(${item.offset?.x})`,
                    marginTop: !this.props.mobile && item.offset?.y,
                    cursor: item.viewable ? "pointer" : "not-allowed",
                    width: this.props.mobile && '100%',
                    marginBottom: this.props.mobile ? 72 : 100
                }}
                onMouseEnter={this.handleMouseEnterParent}
                onMouseLeave={this.handleMouseLeaveParent}
            >
                <FadeInOnScroll
                    delay={400}
                    triggerImmediately={i === 0}
                    style={{width: this.props.mobile ? '100%' : item.image.width}}
                >
                    <TiltEffect
                        scale={{
                            x: .05,
                            y: .05
                        }}
                    >
                        <div
                            className="relative"
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onMouseMove={this.handleMouseMove}
                            style={{
                                transform: `scale(${this.state.isHovered ? 1.01 : 1})`,
                                transition: 'all 350ms ease-in-out',
                                boxShadow: isParentHovered ? `0 10px 20px ${this.props.darkBody ? '#CECECE20' : '#CECECE'}` : `0 4px 10px ${this.props.darkBody ? '#CECECE20' : '#CECECE'}`
                            }}
                            ref={(ref) => (this.wrapperRef = ref)} // Reference to the wrapper div
                        >
                            {this.props.mobile ?
                                <div className='relative' style={{
                                    outline: isParentHovered ? `1px solid ${this.props.darkBody ? '#FFFFFF30' : '#00000030'}` : '0px solid transparent',
                                    outlineOffset: 4
                                }}>
                                    {/*<div style={{overflow: "hidden"}}>*/}
                                    {/*    <img src={item.src}*/}
                                    {/*         style={{marginBottom: -6, objectFit: 'contain', width: "100%"}}/>*/}
                                    {/*</div>*/}
                                    <video autoPlay loop muted playsInline className="splash-video"
                                           style={{
                                               objectFit: 'cover',
                                               width: "100%",
                                               // height: '100%',
                                               marginBottom: -6,
                                               // position: 'absolute',
                                               // top: 0,
                                               // left: 0,
                                               // right: 0,
                                               // bottom: 0
                                           }}>
                                        <source src={item.case.video} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                :
                                <div className='relative' style={{
                                    outline: isParentHovered ? `1px solid ${this.props.darkBody ? '#FFFFFF30' : '#00000030'}` : '0px solid transparent',
                                    outlineOffset: 4
                                }}>
                                    <div style={{overflow: "hidden"}}>
                                        <img src={item.case.src}
                                             style={{marginBottom: -6, objectFit: 'contain', width: "100%"}}/>
                                    </div>
                                    {this.state.inViewport &&
                                        <video autoPlay loop muted className="splash-video"
                                               style={{
                                                   objectFit: 'cover',
                                                   width: "100%",
                                                   // height: '100%',
                                                   marginBottom: -6,
                                                   position: 'absolute',
                                                   top: 0,
                                                   left: 0,
                                                   right: 0,
                                                   bottom: 0
                                               }}>
                                            <source src={item.case.video} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                </div>
                            }
                            <div
                                className="row-plain absolute mt-8 tags-row"
                                style={{
                                    alignItems: "flex-start", bottom: 20, right: this.props.mobile ? 4 : 8,
                                    opacity: this.props.mobile && 1
                                }}
                            >
                                {item.case.tags.map((tag) => (
                                    <div
                                        key={tag}
                                        className="body4-bold uppercase ph-12 pv-4 mr-8 bg-base-black-20 gallery-tag base-white-100"
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </TiltEffect>
                    <div className="row-jb mt-20">
                        <div>
                            <p className={`title1 mr-20 m-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}>{item.case.title}</p>
                            <div
                                className={`row-ac m-0 body4-bold uppercase ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}
                                style={{gap: 4}}>
                                STUDIO:
                                {item.case.zeroToOne &&
                                    <p className='m-0'>
                                        Zero to One
                                    </p>
                                }
                                {item.case.studio && item.case.zeroToOne &&
                                    <SvgTrendingForward style={{width: 14, height: 14}}/>
                                }
                                {item.case.studio &&
                                    <p className='m-0'>
                                        Iteration
                                    </p>
                                }
                            </div>
                            <p
                                className={`body1 m-0 mt-4 ${this.props.darkBody ? 'base-white-50' : 'base-black-50'}`}
                                style={{fontWeight: 500, lineHeight: 1.2}}
                            >
                                {item.case.description}
                            </p>
                        </div>
                        {item.viewable ?
                            <>
                                {this.props.mobile ?
                                    <SvgChevronForward className={` mt-4 ${this.props.darkBody && 'base-white-100'}`}
                                                       style={{width: 16, height: 16}}/>
                                    :
                                    <SvgChevronForward className={` mt-12 ${this.props.darkBody && 'base-white-100'}`}/>
                                }
                            </>
                            :
                            (isParentHovered || this.props.mobile) ?
                                <p className={`m-0 mt-4 body4 uppercase ${this.props.darkBody ? 'base-white-100' : 'base-black-70'}`}>
                                    COMING SOON
                                </p>
                                : null
                        }
                    </div>
                </FadeInOnScroll>
            </div>
        );
    }
}

export default withRouter(WorkFloatingCard);
