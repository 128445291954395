import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
        <path
            d="M52.023 41.568c6.278 0 11.538-3.25 13.033-7.64a34.718 34.718 0 0 0-26.067 0c1.496 4.39 6.756 7.64 13.034 7.64ZM52.023 61.927c-6.278 0-11.538 3.249-13.034 7.64a34.727 34.727 0 0 0 26.067 0c-1.495-4.391-6.755-7.64-13.033-7.64ZM18.523 51.747h67M52.023 78.754V24.74"
            fill="none"
            stroke={'currentColor' || '#000'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <ellipse
            cx={52.023}
            cy={51.747}
            rx={26.946}
            ry={20.359}
            fill="none"
            stroke={'currentColor' || '#000'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <circle
            cx={52.023}
            cy={51.747}
            r={13.473}
            fill="none"
            stroke={'currentColor' || '#000'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <circle
            cx={52.023}
            cy={51.747}
            r={7.091}
            fill="none"
            stroke={'currentColor' || '#000'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
    </svg>
)

export default SvgComponent
