import React, {Component} from "react";
import {BUTTON_TEXT_ZERO_TO_ONE, MVP_STEPS} from "../utils/dummy";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import Shape17 from "../icons/pack1/Shape17";
import {Link} from "react-router-dom";
import WireframeLogo1 from "../icons/custom/wireframelogo2/WireframeLogo1";
import LogoMark from "../icons/custom/LogoMark";
import AnimatedStep from "./AnimatedStep";

class MVPSection extends Component {

    render() {
        return (
            <div className='pb-100 page-h-padding relative'>
                <h1 className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                >
                    The 'Zero to One'<br/> Offering <LogoMark width={this.props.mobile ? 60 : 120}
                                                              height={this.props.mobile ? 16 : 32} fill={'#ffffff50'}/>
                </h1>
                <div className='absolute float' style={{right: '10vw', top: '10vw', opacity: .08}}>
                    <WireframeLogo1 fill={'white'} width={'30vw'} height={'30vw'}/>
                </div>
                <div style={{maxWidth: 800}}>
                    <h1 className={`title1 mt-24 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                    >
                        Your concept. Our <span className='highlight'>scoped sprint</span> to success.
                    </h1>
                    <p className={`title1 mt-32 mb-12 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                       style={{fontWeight: '400'}}
                    >
                        We love a problem to solve. With our technical expertise and proprietary processes, we’ll turn a problem into a real-world solution. We collaborate throughout the process to
                        craft a clear MVP for the problem you defined and help equip you with the right resources
                        and tools to successfully fundraise in today’s environment.
                    </p>
                </div>
                <div className='row-plain relative'>
                    <div className='sticky-section-container' style={{marginRight: '10vw'}}>
                        <div className='sticky-image-container' style={{marginTop: 120}}>
                            <img src={require('../img/Holographic 3D shape 74 (1).png')} className='float'
                                 style={{width: this.props.mobile ? '20vw' : '25vw'}}/>
                        </div>
                    </div>
                    <div style={{maxWidth: 800}}>
                        {MVP_STEPS.map((step, i) =>
                            <FadeInOnScroll delay={10} className='pv-32'>
                                <AnimatedStep key={i} step={step} darkBody={this.props.darkBody}
                                              mobile={this.props.mobile}/>
                            </FadeInOnScroll>
                        )}
                    </div>
                </div>
                <div className={this.props.mobile ? `col-plain pt-100` : `row-jb pt-100`}>
                    <div style={{maxWidth: this.props.mobile ? '100%' : '50vw'}}
                         className={this.props.mobile ? 'pt-32' : 'pt-100'}>
                        <div style={{border: '2px solid white'}} className='p-32'>
                            <p className={`heading2 mt-0 ${this.props.mobile ? 'mb-8' : 'mb-24'} ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                                Interested?
                            </p>
                            <p className={`${this.props.mobile ? 'body1' : 'title1'} mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                               style={{fontWeight: '400'}}
                            >
                                We’re always looking for new founders with a passion for an idea. If you’re interested
                                in talking more about how the Zero to One can work for you, reach out below!
                            </p>
                            <a href={'https://hello.scopelabs.com/'} target={'_blank'}
                               className='secondary-button flex-1 body1-bold mt-32'
                            >
                                {BUTTON_TEXT_ZERO_TO_ONE}
                            </a>
                        </div>
                    </div>
                    <div className='relative'>
                        <div className={`absolute`}
                             style={{
                                 top: this.props.mobile ? 40 : '10vw',
                                 right: this.props.mobile ? '50vw' : '12vw',
                                 width: '26vw',
                                 transition: "600ms ease-in-out",
                             }}
                        >
                            <img src={require('../img/Holographic 3D shape 57.png')}
                                 className='rotate'
                                 style={{
                                     width: this.props.mobile ? '40vw' : '25vw',
                                     opacity: this.props.darkBody ? .5 : 0,
                                     transition: "300ms ease-in-out",
                                     zIndex: -1,
                                     position: "relative"
                                 }}/>
                        </div>
                        <Link to={'/work'} className='absolute base-white-100 row-ac'
                              style={{
                                  top: this.props.mobile ? '25vw' : '25vw',
                                  bottom: !this.props.mobile && 0,
                                  right: this.props.mobile ? '6vw' : 0
                              }}>
                            <Shape17 width={this.props.mobile ? 120 : 200} height={this.props.mobile ? 120 : 200}
                                     style={{
                                         transform: 'rotate(120deg) scaleY(-1)',
                                         marginRight: this.props.mobile ? -32 : -60
                                     }}/>
                            <p className='title1 uppercase mt-0' style={{marginBottom: -6}}>
                                &nbsp;&nbsp;&nbsp;EXPLORE<br/>PAST<br/>BUILDS
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default MVPSection
