import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Home from "./pages/Home";
import './App.css';
import './styles/Formatting.css';
import './styles/ColorStyles.css'
import './styles/Inputs.css'
import './styles/Navbar.css'
import './styles/TextStyles.css'
import './styles/ButtonStyles.css'
import './styles/Components.css'
import './styles/Gallery.css'
import './styles/ModalComponent.css'
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {wait} from "./utils/timestamp";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import ScrollToTop from "./components/ScrollToTop";
import CaseStudy from "./pages/CaseStudy";
import LoadingScreen from "./components/LoadingScreen";
import NavMenu from "./components/NavMenu";
import Work from "./pages/Work";
import ReaCaseStudy from "./pages/ReaCaseStudy";
import ZeroToOnePage from "./pages/ZeroToOnePage";
import Studio from "./pages/Studio";
import RecruitCaseStudy from "./pages/RecruitCaseStudy";
import EvolveCaseStudy from "./pages/EvolveCaseStudy";
import GlossiCaseStudy from "./pages/GlossiCaseStudy";
import EpicCaseStudy from "./pages/EpicCaseStudy";
import ShowOpenCaseStudy from "./pages/ShowOpenCaseStudy";
import TheLab from "./pages/TheLab";
import StonkMadnessCaseStudy from "./pages/StonkMadnessCaseStudy";
import AigentlessCaseStudy from "./pages/AigentlessCaseStudy";
import SeatClubCaseStudy from "./pages/SeatClubCaseStudy";


class App extends Component {
    state = {
        darkBody: false,
        loading: true,
        mobile: false,
        tablet: false,
        navOpen: false
    }

    toggleDarkNavbar = (darkBody, timer) => {
        wait(timer || 0).then(() =>
            this.setState({darkBody})
        )
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        document.body.style.overflow = 'hidden';
        wait(2500).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.loading && this.state.loading) {
            document.body.style.overflow = 'hidden';
        }
        if (prevState.loading && !this.state.loading) {
            wait(600).then(() =>
                document.body.style.overflow = 'auto'
            )
        }
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth > 760 && window.innerWidth <= 1200)
        });
    }

    toggleNavMenu = () => {
        if (!this.state.navOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto'
        }
        this.setState({navOpen: !this.state.navOpen})
    }

    hideNavMenu = () => {
        document.body.style.overflow = 'auto'
        this.setState({navOpen: false})
    }

    render() {
        return (
            <div className='relative'>
                <Router>
                    <ScrollToTop/>
                    <Navbar darkBody={this.state.darkBody}
                            triggerLoad={this.triggerLoad}
                            toggleDarkNavbar={this.toggleDarkNavbar}
                            mobile={this.state.mobile}
                            toggleNavMenu={this.toggleNavMenu}
                            hideNavMenu={this.hideNavMenu}
                            navOpen={this.state.navOpen}
                    />
                    <Switch>
                        <Route exact path="/"
                               render={(routeProps) => <Home {...routeProps}
                                                             loading={this.state.loading}
                                                             toggleDarkNavbar={this.toggleDarkNavbar}
                                                             tablet={this.state.tablet}
                                                             mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/work" render={(routeProps) => <Work {...routeProps}
                                                                                loading={this.state.loading}
                                                                                mobile={this.state.mobile}
                                                                                toggleDarkNavbar={this.toggleDarkNavbar}/>}
                        />
                        <Route exact path="/about" render={(routeProps) => <About {...routeProps}
                                                                                  loading={this.state.loading}
                                                                                  mobile={this.state.mobile}
                                                                                  toggleDarkNavbar={this.toggleDarkNavbar}/>}
                        />
                        <Route exact path="/contact" render={(routeProps) => <ContactUs {...routeProps}
                                                                                        loading={this.state.loading}
                                                                                        mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/iteration" render={(routeProps) => <Studio {...routeProps}
                                                                                    loading={this.state.loading}
                                                                                    mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/lab" render={(routeProps) => <TheLab {...routeProps}
                                                                                    loading={this.state.loading}
                                                                                    mobile={this.state.mobile}
                                                                                 tablet={this.state.tablet}
                        />}/>
                        <Route exact path="/work/starfish" render={(routeProps) => <CaseStudy {...routeProps}
                                                                                              tablet={this.state.tablet}
                                                                                              loading={this.state.loading}
                                                                                              mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/work/evolve" render={(routeProps) => <EvolveCaseStudy {...routeProps}
                                                                                              tablet={this.state.tablet}
                                                                                              loading={this.state.loading}
                                                                                              mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/work/rea" render={(routeProps) => <ReaCaseStudy {...routeProps}
                                                                                            tablet={this.state.tablet}
                                                                                            loading={this.state.loading}
                                                                                            mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/work/aigentless" render={(routeProps) => <AigentlessCaseStudy {...routeProps}
                                                                                            tablet={this.state.tablet}
                                                                                            loading={this.state.loading}
                                                                                            mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/work/seatclub" render={(routeProps) => <SeatClubCaseStudy {...routeProps}
                                                                                                          tablet={this.state.tablet}
                                                                                                          loading={this.state.loading}
                                                                                                          mobile={this.state.mobile}
                        />}/>
                        <Route exact path="/work/recruit"
                               render={(routeProps) => <RecruitCaseStudy {...routeProps}
                                                                         loading={this.state.loading}
                                                                         mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/work/epic"
                               render={(routeProps) => <EpicCaseStudy {...routeProps}
                                                                         loading={this.state.loading}
                                                                         mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/work/showopen"
                               render={(routeProps) => <ShowOpenCaseStudy {...routeProps}
                                                                      loading={this.state.loading}
                                                                      mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/work/glossi"
                               render={(routeProps) => <GlossiCaseStudy {...routeProps}
                                                                         loading={this.state.loading}
                                                                         mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/work/stonkmadness"
                               render={(routeProps) => <StonkMadnessCaseStudy {...routeProps}
                                                                        loading={this.state.loading}
                                                                        mobile={this.state.mobile}
                               />}/>
                        <Route exact path="/zero-to-one" render={(routeProps) => <ZeroToOnePage {...routeProps}
                                                                                                tablet={this.state.tablet}
                                                                                                loading={this.state.loading}
                                                                                                mobile={this.state.mobile}
                                                                                                toggleDarkNavbar={this.toggleDarkNavbar}
                        />}/>
                        <Redirect to={'/'}/>
                    </Switch>
                    <Footer mobile={this.state.mobile}/>
                    <NavMenu
                        navOpen={this.state.navOpen}
                        mobile={this.state.mobile}
                        tablet={this.state.tablet}
                        toggleNavMenu={this.toggleNavMenu}
                    />
                </Router>
                <LoadingScreen loading={this.state.loading} mobile={this.state.mobile}/>
            </div>
        );
    }
}

export default App
