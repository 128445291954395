import React, {Component} from 'react';
import '../styles/Navbar.css';
import NavbarItem from "./NavbarItem";
import LogoMark from "../icons/custom/LogoMark";
import {Link, withRouter} from "react-router-dom";
import SvgHourglass from "../icons/SvgHourglass";
import SvgMenu from "../icons/SvgMenu";
import DropdownMenu from "./DropdownMenu";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            prevScrollPos: window.pageYOffset,
            transparentNav: true,
            whiteNavbar: true
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (this.props.location.pathname.includes('/lab')) {
            this.props.toggleDarkNavbar(true)
            this.setState({whiteNavbar: true})
            document.body.style.backgroundColor = 'black';
        } else if (this.props.location.pathname.includes('/work/')) {
            this.props.toggleDarkNavbar(true)
            this.setState({whiteNavbar: true})
        } else {
            this.setState({whiteNavbar: false})
            this.props.toggleDarkNavbar(false)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.hideNavMenu()
            if (this.props.location.pathname.includes('/lab')) {
                this.props.toggleDarkNavbar(true)
                this.setState({whiteNavbar: true})
                document.body.style.backgroundColor = 'black';
            } else if (this.props.location.pathname.includes('/work/')) {
                document.body.style.overflow = 'auto';
                document.body.style.backgroundColor = 'white';
                this.props.toggleDarkNavbar(true)
                this.setState({whiteNavbar: true})
            } else {
                document.body.style.backgroundColor = 'white';
                this.props.toggleDarkNavbar(false)
                this.setState({whiteNavbar: false})
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const currentScrollPos = window.pageYOffset;
        const visible = this.state.prevScrollPos > currentScrollPos || currentScrollPos <= 0;
        const isViewingHero = currentScrollPos <= 200
        this.setState({
            visible,
            prevScrollPos: currentScrollPos,
            transparentNav: isViewingHero
        });
    }

    render() {
        const {visible} = this.state;
        if (this.props.mobile) {
            return (
                <>
                    <nav
                        className={`navbar row-ac-jb page-h-padding ${visible ? 'visible' : 'hidden'} ${this.props.darkBody ? 'bg-base-black-100' : (this.state.transparentNav ? 'bg-transparent' : 'bg-base-white-80')}`}
                        style={{
                            backgroundColor: this.state.whiteNavbar && (((visible) && !this.state.transparentNav) ? "black" : 'transparent'),
                            color: this.state.whiteNavbar && "white",
                            transition: '300ms ease-in-out',
                            boxSizing: "border-box",
                            height: 80,
                        }}
                    >
                        <div className='col-ac-jc relative'>
                            <Link to={'/'}
                                  className={`navbar-section navbar-section-left transition1  ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}>
                                <LogoMark style={{width: 120, height: 40}}/>
                            </Link>
                        </div>
                        <div onClick={this.props.toggleNavMenu}
                             className='col-ac-jc'
                             style={{
                                 transform: `scale(${this.props.navOpen ? '.5' : '1'}`,
                                 transition: "transform 300ms ease-in-out"
                             }}>
                            <SvgMenu width={32} height={32}
                                     className={`${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}/>
                        </div>
                    </nav>
                </>
            )
        }
        return (
            <div>
                <nav
                    className={`navbar page-h-padding ${visible ? 'visible' : 'hidden'} ${this.props.darkBody ? 'bg-base-black-100' : (this.state.transparentNav ? 'bg-transparent' : 'bg-base-white-80')}`}
                    style={{
                        borderColor: (this.props.darkBody || this.state.whiteNavbar) && '#ffffff40',
                        backgroundColor: this.state.whiteNavbar && (((visible) && !this.state.transparentNav) ? "black" : 'transparent'),
                        color: this.state.whiteNavbar && "white",
                        transition: '300ms ease-in-out',
                        boxSizing: 'border-box'
                    }}
                >
                    <Link to={'/'}
                          className={`navbar-section navbar-section-left transition1  ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}>
                        <LogoMark width={144} height={62}/>
                        <p style={{display: "none"}}>
                            Scope Labs
                        </p>
                    </Link>
                    <div className="navbar-section navbar-section-middle transition1"
                         style={{borderColor: this.props.darkBody && '#ffffff40'}}>
                        <ul className="navbar-nav row-ac">
                            {/*<NavbarItem label={'Home'} route={'/'} darkBody={this.props.darkBody}/>*/}
                            <DropdownMenu>
                                <div style={{pointerEvents: ""}}>
                                    <NavbarItem label={'Studio'}
                                                active={(this.props.location.pathname === '/zero-to-one') || this.props.location.pathname === '/iteration'}
                                                darkBody={this.props.darkBody}/>
                                </div>
                            </DropdownMenu>
                            {/*<NavbarItem label={'Iteration'} route={'/studio'}*/}
                            {/*            active={this.props.location.pathname === '/studio'}*/}
                            {/*            darkBody={this.props.darkBody}/>*/}
                            <div className='relative'>
                                <NavbarItem label={'Incubator'} route={'/lab'}
                                            active={this.props.location.pathname === '/lab'}
                                            darkBody={this.props.darkBody}/>
                                <div className='absolute' style={{top: 2, right: 12}}>
                                    <p className='highlight body4-bold'
                                       style={{color: this.props.darkBody && 'white'}}>
                                        NEW
                                    </p>
                                </div>
                            </div>
                            <NavbarItem label={'Our Work'} route={'/work'}
                                        active={this.props.location.pathname.slice(0, 5) === '/work'}
                                        darkBody={this.props.darkBody}/>
                            {/*<NavbarItem*/}
                            {/*    label={'Capital'}*/}
                            {/*    external={'https://scopelabscapital.com/'}*/}
                            {/*    active={false}*/}
                            {/*    darkBody={this.props.darkBody}*/}
                            {/*/>*/}
                            <NavbarItem label={'About Us'} route={'/about'}
                                        active={this.props.location.pathname === '/about'}
                                        darkBody={this.props.darkBody}/>
                            <div onClick={this.props.toggleNavMenu}
                                 className='col-ac-jc pointer'
                                 style={{
                                     transform: `scale(${this.props.navOpen ? '.5' : '1'}`,
                                     transition: "transform 300ms ease-in-out"
                                 }}>
                                <SvgMenu width={24} height={24}
                                         className={`${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}/>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withRouter(Navbar);
