import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M92.248 82.692c-7.376-30.44-19.167-45.66-30.958-45.66S37.707 52.23 30.33 82.668"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
            d="m36.896 74.829-6.565 7.838-1.906-10.044M94.154 72.648l-1.906 10.044-6.565-7.838"
        />
        <path
            d="M61.154 75.32c7.375 30.439 19.167 45.659 30.958 45.659s23.583-15.196 30.958-45.635"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
            d="m116.505 83.182 6.565-7.838 1.906 10.045M59.247 85.364l1.907-10.045 6.564 7.838"
        />
        <circle
            cx={76.701}
            cy={79.006}
            r={30.799}
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
